import React, { useState } from "react";
export const UserContext = React.createContext(undefined);
export const UserDispatchContext = React.createContext(undefined);

// TOKEN MANAGEMENT

const TOKEN_KEY = 'token'
const TOKEN_EXPIRE_KEY = 'expires_at'
const REFRESH_TOKEN_KEY = 'refreshToken'

const USER_INFO_KEY = 'usr'

function storeToken(token) {
    localStorage.setItem(TOKEN_KEY, token.accessToken);
    localStorage.setItem(TOKEN_EXPIRE_KEY, token.expiresAt);
    localStorage.setItem(REFRESH_TOKEN_KEY, token.refreshToken);
}

function deleteToken(){
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(TOKEN_EXPIRE_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
}

function isTokenExpired() {
    const expiresAt = localStorage.getItem(TOKEN_EXPIRE_KEY);
    return new Date().getTime() > expiresAt;
}

function getToken() {
    return localStorage.getItem(TOKEN_KEY);
}

function isLogged() {
    return getToken() !== null && !isTokenExpired()
}

function storeUser(user) {
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(user));
}

function getUser() {
    const userJson = localStorage.getItem(USER_INFO_KEY);
    return userJson ? JSON.parse(userJson) : null;
}

function deleteUser() {
    localStorage.removeItem(USER_INFO_KEY)
}

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState();

    // USER INFO
    const setUserInfo = async () => {
        if(user) {
            return 
        }
        else {
            const storedUser = getUser()
            if(storedUser){
                setUser(storedUser)
                return
            }

            const token = getToken();
            if(token){
                await fetch(`${process.env.REACT_APP_API_HOSTNAME}/users/me`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(response => response.json())
                .then(data => {
                    setUser(data)
                })
                .catch(error => {
                    console.error("Error fetching user data", error);
                });
            }
        }
    }

    return (
        <UserContext.Provider value={{user, isLogged, setUserInfo, getToken, isTokenExpired}}>
            <UserDispatchContext.Provider value={{setUser, storeToken, deleteToken, storeUser, getUser, deleteUser}}>
                {children}
            </UserDispatchContext.Provider>
        </UserContext.Provider>
    );
}