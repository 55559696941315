import React from "react";
import content from "../Job.json"
import {Button} from "../../../components/button/Button";

export const JobActionBtns = ({isResponseDone, queriesLength, getLinkedinPost, getCandidates, getInterviewQuestions}) => {
    return (
        <div className={"actions-buttons-wrapper"}>
            <div className={"actions-buttons"}>
                <Button
                    variant={"primary"}
                    size={"small"}
                    icon={content.actionsBtns[0].icon}
                    label={content.actionsBtns[0].label}
                    onClick={getLinkedinPost}
                    disabled={!isResponseDone && queriesLength > 0}
                />
                <Button
                    variant={"primary"}
                    size={"small"}
                    icon={content.actionsBtns[1].icon}
                    label={content.actionsBtns[1].label}
                    onClick={getCandidates}
                    disabled={!isResponseDone && queriesLength > 0}
                />
                <Button
                    variant={"primary"}
                    size={"small"}
                    icon={content.actionsBtns[2].icon}
                    label={content.actionsBtns[2].label}
                    onClick={getInterviewQuestions}
                    disabled={!isResponseDone && queriesLength > 0}
                />
            </div>
        </div>
    )
}