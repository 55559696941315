export const getSuggestions = (placements, inputValue) => {
    let suggestionList = []
    if (!placements || placements.length <= 0) return suggestionList
    const filteredNames = placements.filter((placement) => {
        const firstName = placement?.candidate_firstname
        const lastName = placement?.candidate_lastname
        const clientName = placement?.client_name
        if (firstName.length <=0) return
        if (lastName.length <=0) return
        if (clientName.length <=0) return
        return(
            firstName?.toLowerCase().startsWith(inputValue.trim().toLowerCase()) ||
            lastName?.toLowerCase().startsWith(inputValue.trim().toLowerCase()) ||
            clientName?.toLowerCase().includes(inputValue.trim().toLowerCase()) ||
            `${firstName} ${lastName} - ${clientName}`?.toLowerCase().startsWith(inputValue.trim().toLowerCase())
        )
    })
    filteredNames.forEach((placement) => {
        suggestionList.push({
            submission_id: placement?.submission_id,
            text: `${placement?.candidate_firstname} ${placement?.candidate_lastname} - ${placement?.client_name}`})
    })
    return suggestionList
}