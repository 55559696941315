import React, {useContext, useState} from 'react';
import "./Candidates.scss";
import content from "./Candidates.json";
import { useNavigate } from 'react-router-dom';
import {PageIntro} from "../../components/page-intro/PageIntro";
import {Page} from "../../layouts/Page";
import {CandidatesTable} from "./components/CandidatesTable";

/**
 * Candidates Page
 */
export const Candidates = () => {
    const [loadingStatus, setLoadingStatus] = useState(true)
    const navigate = useNavigate()


    const handleRowSelect = (candidateId) => {
        navigate('/candidates/' + candidateId);
    }

    return (
        <Page id={"candidates-page"}>
            <PageIntro
                title={content.title}
                subTitle={content.subTitle}
                description={content.description}
                variant={"secondary"}
            />
            <CandidatesTable
                handleRowSelect={handleRowSelect}
                loadingStatus={loadingStatus}
                displayFilters={true}
            />
        </Page>
    )
}
