import React from 'react';
import "./Textarea.scss";

/**
 * Textarea component for main UI
 */
export const Textarea = ({label, id, placeholder, variant, type, isRequired, reference, onSubmit, disableSubmit, ...props}) => {
    return (
        <span className={"textarea-wrapper"}>
            {label !== undefined &&
                <label className={"label"} data-variant={variant} htmlFor={id}>{label}</label>
            }
            <span className={"textarea-border-clip"}>
                 <textarea
                     id={id}
                     className={'textarea'}
                     placeholder={placeholder}
                     data-variant={variant}
                     name={type}
                     required={isRequired}
                     ref={reference}
                     {...props}
                 />
            </span>
        </span>
    );
}