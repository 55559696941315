import React, {useRef, useState, useEffect} from 'react';
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PropTypes from "prop-types";
import "./Modal.scss"


export const Modal = ({children, show, modalHeight = 200, modalWidth = 300, event}) => {

    const [modalStyle, setModalStyle] = useState({});
    const [isVisible, setIsVisible] = useState(false)
    const outerReference = useRef()

    useOnClickOutside(outerReference, () => {
        setIsVisible(false)
    })


    useEffect(() => {
        if (show && event) {
            const origin = event.target.getBoundingClientRect();
            const left = origin.left + window.scrollX;
            const spaceBelow = window.innerHeight - origin.bottom;
            const spaceAbove = origin.top;
            let top;
            
            if (spaceBelow >= modalHeight || spaceBelow > spaceAbove) {
                top = origin.bottom + window.scrollY;
            } else {
                top = origin.top + window.scrollY - modalHeight;
            }
    
            setModalStyle({
                top: `${top}px`,
                left: `${left}px`,
                width: `${modalWidth}px`
            });
        }
        setIsVisible(show)
    }, [show, event, modalHeight]);
    

    if (!isVisible) return null;

    return(
        <div style={modalStyle} className="modal" ref={outerReference}>
            {children}
        </div>
    )

}

export const ModalList = ({show = false, modalHeight, modalWidth, event, items, onClick}) => {

    return(
        <Modal show={show} modalHeight={modalHeight} modalWidth={modalWidth} event={event}>
            <SuggestionList dataPos="bottom" map={items.map((item, index) =>
                <React.Fragment key={`${item.text}-${index}`}>
                    {
                        <li className={"auto-complete-item"} tabIndex={0} onClick={() => onClick(item.id)}>
                            {item.text && item.text}
                            {item.content && item.content}
                        </li>
                    }
                </React.Fragment>
            )}/>
        </Modal>
    )

 }

function SuggestionList(props) {
    return <ul id={"auto-complete-" + props.id} className={"auto-complete-list"} data-pos={props.dataPos}>
        {props.map}
    </ul>;
}

SuggestionList.propTypes = {
    id: PropTypes.any,
    dataPos: PropTypes.string,
    map: PropTypes.any
};