import {CollapsableContainer} from "../../../components/collapsable-container/CollapsableContainer";
import React from "react";
import {Button} from "../../../components/button/Button";

export const RightSection = () => {

    const handleOnModifyClick = (sectionName) => {

    }

    return (
        <div className={"panel-layout-container"}>
            <CollapsableContainer>
                <CollapsableContainer.Header
                    icon={"fa-duotone fa-id-card"}
                    title={"Présentation"}
                >
                    <Button
						label={"Modifier"}
						size={"small"}
						variant={"primary"}
						onClick={() => handleOnModifyClick("presentation")}
					/>
                </CollapsableContainer.Header>
                <CollapsableContainer.Body>
					{/*TODO: add content of container here*/}
                </CollapsableContainer.Body>
            </CollapsableContainer>
            <CollapsableContainer>
                <CollapsableContainer.Header
                    icon={"fa-duotone fa-head-side-gear"}
                    title={"Compétences techniques"}
                >
					<Button
						label={"Modifier"}
						size={"small"}
						variant={"primary"}
						onClick={() => handleOnModifyClick("skills")}
					/>
                </CollapsableContainer.Header>
                <CollapsableContainer.Body>
					{/*TODO: add content of container here*/}
                </CollapsableContainer.Body>
            </CollapsableContainer>
            <CollapsableContainer>
                <CollapsableContainer.Header
                    icon={"fa-duotone fa-briefcase"}
                    title={"Expériences"}
                >
					<Button
						label={"Modifier"}
						size={"small"}
						variant={"primary"}
						onClick={() => handleOnModifyClick("experiences")}
					/>
                </CollapsableContainer.Header>
                <CollapsableContainer.Body>
					{/*TODO: add content of container here*/}
                </CollapsableContainer.Body>
            </CollapsableContainer>
            <CollapsableContainer>
                <CollapsableContainer.Header
                    icon={"fa-duotone fa-graduation-cap"}
                    title={"Diplômes"}
                >
					<Button
						label={"Modifier"}
						size={"small"}
						variant={"primary"}
						onClick={() => handleOnModifyClick("diplomats")}
					/>
                </CollapsableContainer.Header>
                <CollapsableContainer.Body>
					{/*TODO: add content of container here*/}
                </CollapsableContainer.Body>
            </CollapsableContainer>
            <CollapsableContainer>
                <CollapsableContainer.Header
                    icon={"fa-duotone fa-file-certificate"}
                    title={"Certifications"}
                >
					<Button
						label={"Modifier"}
						size={"small"}
						variant={"primary"}
						onClick={() => handleOnModifyClick("certifications")}
					/>
                </CollapsableContainer.Header>
                <CollapsableContainer.Body>
					{/*TODO: add content of container here*/}
                </CollapsableContainer.Body>
            </CollapsableContainer>
        </div>
    )
}