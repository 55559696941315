import React, {useContext, useState} from "react";
import content from '../../Meeting.json'
import {CollapsableContainer} from "../../../../components/collapsable-container/CollapsableContainer";
import {Textarea} from "../../../../components/textarea/Textarea";
import {Button} from "../../../../components/button/Button";
import {JobDescription} from "./JobDescription";
import {GeneratedQuestions} from "./GeneratedQuestions";
import {fetchStream} from "../../../../apis/getRequest";
import {UserContext} from "../../../../context/UserContext";
import {interviewStream} from "../../helpers/interviewStream";

export const InterviewSection = ({selectedJob, setSelectedJob, notes, handleOnClick, handleOnNoteChange, handleOnNoteBlur}) => {
    const { getToken } = useContext(UserContext)
    const [collapsableMenuState, setCollapsableMenuState] = useState([true, false, true])
    const [questions, setQuestions] = useState([])
    const [, setIsResponseDone] = useState(false)
    const [isQuestionLoading, setIsQuestionLoading] = useState(false)

    const {
        onSourceOpen,
        onSourceMessage,
        onSourceError,
        onSourceClose
    } = interviewStream({
        response: questions,
        setResponse: setQuestions,
        setIsResponseDone: setIsResponseDone
    })

    const handleOnToggle = async (e, index) => {
        e.preventDefault()
        setCollapsableMenuState((prevState) => {
            const currState = [...prevState]
            currState[index] = !currState[index]
            return currState
        })
    }

    const onGenerateQuestionsBtn = async () => {
        setIsQuestionLoading(true)
        try {
            await fetchStream({
                url: `${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/${selectedJob?.id}/interview`,
                token: getToken(),
                onmessage: onSourceMessage,
                onopen: onSourceOpen,
                onclose: onSourceClose,
                onSourceError: onSourceError,
            })
            setIsQuestionLoading(false)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <div className={"panel-layout-container"}>
                <CollapsableContainer id={"job-description-container"} isOpen={collapsableMenuState[0]}>
                    <CollapsableContainer.Header
                        icon={content.jobDescription.icon}
                        title={content.jobDescription.sectionName}
                        handleOnToggle={(e) => handleOnToggle(e, 0)}
                    />
                    <CollapsableContainer.Body>
                        <JobDescription
                            selectedJob={selectedJob}
                            setSelectedJob={setSelectedJob}
                            setQuestions={setQuestions}
                        />
                    </CollapsableContainer.Body>
                </CollapsableContainer>
                <CollapsableContainer isOpen={collapsableMenuState[1]}>
                    <CollapsableContainer.Header
                        icon={content.generatedQuestions.icon}
                        title={content.generatedQuestions.sectionName}
                        handleOnToggle={(e) => handleOnToggle(e, 1)}
                    />
                    <CollapsableContainer.Body>
                        <GeneratedQuestions
                            selectedJob={selectedJob}
                            questions={questions}
                            handleOnClick={onGenerateQuestionsBtn}
                            isQuestionLoading={isQuestionLoading}
                        />
                    </CollapsableContainer.Body>
                </CollapsableContainer>
                <CollapsableContainer isOpen={collapsableMenuState[2]}>
                    <CollapsableContainer.Header
                        icon={content.notes.icon}
                        title={content.notes.sectionName}
                        handleOnToggle={(e) => handleOnToggle(e, 2)}
                    />
                    <CollapsableContainer.Body>
                        <Textarea
                            placeholder={content.notes.inputPlaceholder}
                            variant={"secondary"}
                            value={notes}
                            onChange={handleOnNoteChange}
                            onBlur={handleOnNoteBlur}
                            style={{minHeight: '180px'}}
                        />
                    </CollapsableContainer.Body>
                </CollapsableContainer>
            </div>
            <div className={"interview-action-button"}>
                <Button
                    variant={"primary"}
                    label={content.buttonLabels.firstConfirmationLabel}
                    onClick={handleOnClick}
                />
            </div>
        </>
    )
}