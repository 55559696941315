import React, {useContext, useEffect, useState} from 'react';
import { Outlet, useLocation} from 'react-router'
import "./Layouts.scss";
import {MainMenu} from "../components/main-menu/MainMenu.jsx";
import {Header} from "../components/header/Header.jsx";
import {Background} from "../components/bg/Background.jsx";
import {LayoutContext} from "./LayoutContext";

/**
 * Layout for main UI
 */
export const Layout = () => {
    const {menuLinks} = useContext(LayoutContext);
    const [isMenuOpen, setIsMenuOpen] = useState(true)
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            <Background/>
            <div className={"layout-wrapper"} data-collapsed={isMenuOpen} data-display-left-menu={menuLinks ? "true" : "false"}>
                <Header />
                <MainMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} isDisplayed={menuLinks ? "true" : "false"} links={menuLinks}/>
                <div className={"page-wrapper"}>
                    <Outlet/>
                </div>
            </div>
        </>
    );
}