import Card from "../../../components/card/Card";
import {Tag} from "../../../components/tag/Tag";
import React from "react";

export const JobCard = ({job}) => {
    const formatDate = (timestamp) => {
        const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'}
        return new Intl.DateTimeFormat('ca-FR', dateFormat).format(timestamp)
    }

    return (

        <Card>
            <Card.Body>
                <Card.Description>
                    <div dangerouslySetInnerHTML={{ __html: job?.description }} />
                </Card.Description>
            </Card.Body>
        </Card>
    )
}