import "./Dropdown.scss"
import React, {useRef, useState} from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";

export const Dropdown = ({children, alignment = "left", fitWidth = false}) => {
    const ref = useRef()
    const [isOpen, setIsOpen] = useState(false)

    useOnClickOutside(ref, () => {
        setIsOpen(false)
    })

    const handleOnClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div
            ref={ref}
            className={"dropdown"}
            data-fit-width={fitWidth}
            data-state={isOpen ? "open" : "close"}
            data-align={alignment}
            onClick={handleOnClick}
        >
            {children}
        </div>
    )
}

Dropdown.Button = function DropdownButton ({children}) {
    return (
        <div className={"dropdown-button-wrapper"}>
            {children}
        </div>
    )
}

Dropdown.Menu = function DropdownMenu ({children}) {
    return (
        <ul className={"dropdown-menu-wrapper"}>
            {children}
        </ul>
    )
}

Dropdown.MenuItem = function DropdownMenuItem ({children, onClick}) {
    const handleOnClick = (e) => {
        if (!onClick) {
            e.stopPropagation()
            return
        }
        onClick()
    }

    return (
        <li
            className={"dropdown-menu-item-wrapper"}
            data-variant={onClick ? 'clickable' : 'not-clickable'}
            onClick={handleOnClick}
        >
            {children}
        </li>
    )
}
