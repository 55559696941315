import React, {useContext, useRef, useState, useEffect} from 'react';
import "./RecruiterChat.scss";
import content from "./RecruiterChat.json";
import {InputSuggestion} from "../../../components/input-suggestion/InputSuggestion.jsx";
import {InputField} from "../../../components/input-field/InputField.jsx";
import {Footer} from "../../../components/footer/Footer.jsx";
import {UserContext} from "../../../context/UserContext";
import {Button} from "../../../components/button/Button";
import {PageIntro} from "../../../components/page-intro/PageIntro";
import {fetchStream, fetchDirect} from "../../../apis/getRequest";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {chatStream} from "../../../helpers/chatStream";
import useMainInputRef from "../../../hooks/useMainInputRef";
import {Page} from "../../../layouts/Page";
import {ChatBubbles, MainInputWrapper, PageAnchor, Suggestions} from "./components/wrappers";
import {ChatBubble} from "../../../components/chat/chat-bubble/ChatBubble";

const RESPONSE_STATE = {text: "agent-text", loading: "agent-loading"};

/**
 * RecruiterChat Page
 */
export const RecruiterChat = () => {
    const {user} = useContext(UserContext)
    const { getToken } = useContext(UserContext)
    const newResponse = useRef()
    const mainInputRef = useMainInputRef()
    const navigate = useNavigate()
    const location = useLocation()

    const [querySuggestions] = useState(
        Array.from(
            { length: Object.keys(content.suggestions).length },
            (_, index)=> (
            {
                icon: content.suggestions[`suggestion${index + 1}`].icon,
                text: content.suggestions[`suggestion${index + 1}`].text,
                link: content.suggestions[`suggestion${index + 1}`].link
            }
        ))
    )

    useEffect(() => {
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/ask/reset`, getToken())
    }, [location.pathname, getToken])

    const [userQueries, setUserQueries] = useState([])
    const [responses, setResponses] = useState([]);
    const [isResponseDone, setIsResponseDone] = useState(false)
    const [hasError] = useState({errorMsg: ""})
    const {
        onSourceOpen, onSourceMessage, onSourceError, onSourceClose
    } = chatStream({
        responses: responses,
        setResponses: setResponses,
        setIsResponseDone: setIsResponseDone,
        newResponse: newResponse
    })

    const handleOnSuggestionClick = (suggestionKey) => {
        const suggestion = querySuggestions[suggestionKey]
        if(suggestion?.link) navigate(suggestion?.link, { replace: true })
        else onSubmitQuery(suggestion?.text)
    }

    const handleOnInputClick = () => {
        onSubmitQuery(mainInputRef.current.value)
        mainInputRef.current.value = ""
    }

    const handleOnRegenerate = () => {
        onSubmitQuery(userQueries[userQueries.length - 1])
    }

    const onSubmitQuery = async (newQuery) => {
        setIsResponseDone(false)
        window.scrollTo(0, document.body.scrollHeight)
        setUserQueries([...userQueries, newQuery])

        const url = `${process.env.REACT_APP_API_HOSTNAME}/recruitment/resumes/query/?query=${newQuery}`
        try {
            await fetchStream({
                url: url,
                token: getToken(),
                onopen: onSourceOpen,
                onmessage: onSourceMessage,
                onclose: onSourceClose,
                onerror:onSourceError
            })
        } catch (e) {
            console.error(e)
        }
    }

    const handleOnKeyDown = (e) => {
        //press enter
        if (e.keyCode === 13) {
            handleOnInputClick()
        }
    }
    return (
        <Page chat={true}>
            <PageIntro
                title={content.title}
                subTitle={content.subTitle}
                description={content.description}
            />
            <PageAnchor>
                <Suggestions
                    suggestions={querySuggestions}
                    bubbleCount={userQueries.length}
                    callbackfn={(suggestion, i) =>
                        <React.Fragment key={"suggestion-item-" + i}>
                            <InputSuggestion
                                sKey={i}
                                icon={suggestion.icon}
                                text={suggestion.text}
                                onClick={handleOnSuggestionClick}
                            />
                        </React.Fragment>
                    }
                />
                <ChatBubbles bubbleCount={userQueries.length}>
                    <>
                        {userQueries.map((userQuery, i) =>
                            <React.Fragment key={"chat-bubble-" + i}>
                                <ChatBubble
                                    text={userQuery}
                                    avatarInitial={user?.name?.charAt(0)}
                                    avatarImage={user?.profile_pic && `data:image/jpeg;base64,${user.profile_pic}`}
                                    variant={"user-text"}
                                />
                                <ChatBubble
                                    text={responses[i] ? responses[i] : "..."}
                                    avatarInitial={"AI"}
                                    variant={responses[i] ? RESPONSE_STATE.text : RESPONSE_STATE.loading}
                                    delayDisplay={550}
                                    isResponseDone={i !== userQueries.length - 1 || isResponseDone}
                                    reference={i === userQueries.length - 1 ? newResponse : null}
                                />
                            </React.Fragment>
                        )}
                        {hasError.errorMsg &&
                            <span className={"errorMsg"}>{hasError.errorMsg}</span>
                        }
                        <Button
                            icon={"fa-solid fa-rotate-right"}
                            variant={"tertiary"}
                            label={content.regenerateBtnText}
                            onClick={handleOnRegenerate}
                            style={
                                responses.length <= 0 ?
                                    {opacity: "0", transform: "scale(0)", display: "none"}
                                    : isResponseDone ?
                                        {opacity: "1", transform: "scale(1)"}
                                        :
                                        {opacity: "1", transform: "scale(1)", display: "none"}
                            }
                        />
                    </>
                </ChatBubbles>
                <MainInputWrapper>
                    <InputField
                        id={"main-input"}
                        variant={"submit"}
                        variantType={"submit"}
                        placeholder={content.inputPlaceholderText}
                        reference={mainInputRef}
                        disableSubmit={!isResponseDone && userQueries.length > 0}
                        onSubmit={handleOnInputClick}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        autoComplete="off"
                    >
                    </InputField>
                </MainInputWrapper>
            </PageAnchor>
            <Footer/>
        </Page>
    );
}