import React, {useContext, useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router-dom';
import "./Candidate.scss";
import content from "./Candidate.json";
import {UserContext} from "../../context/UserContext.js";
import {fetchDirect} from "../../apis/getRequest";
import {PageIntro} from "../../components/page-intro/PageIntro";
import { Spinner } from '../../components/spinner/Spinner.jsx';
import {Page} from "../../layouts/Page";
import {PageAnchor} from "../../layouts/PageAnchor";
import {chatStream} from "../../helpers/chatStream";
import {Tag} from "../../components/tag/Tag";
import {Avatar} from "../../components/avatar/Avatar.jsx";
import {Footer} from "../../components/footer/Footer";

/**
 * Candidate Page
 */
export const Candidate = () => {
    const { getToken } = useContext(UserContext)
    const { candidateId } = useParams()
    const newResponse = useRef()

    const [responses, setResponses] = useState([])
    const [candidate, setCandidate] = useState({})
    const [loadingStatus, setLoadingStatus] = useState(0)
    const [usersPics, setUsersPics] = useState({})

    const formatDate = (timestamp) => {
        const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'}
        return new Intl.DateTimeFormat('ca-FR', dateFormat).format(timestamp)
    }

    useEffect(() =>{
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/users/pictures`, getToken())
        .then((pictures) => {
            const usersPics = {}
            pictures?.forEach(p => usersPics[p.integration_id] = p.profile_pic )
            setUsersPics(usersPics)
        });
    },[getToken])

    useEffect(() => {
        if(loadingStatus === 0){
            setLoadingStatus(1)
            fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/candidates/${candidateId}`, getToken())
            .then((response) => {
                setCandidate(response)
                setLoadingStatus(2)
            })
            .catch((e) => {console.error(e)})
        }

    }, [candidateId])

    return (
        <Page id="candidate-page">
            <Spinner visible={candidate === undefined} />
            {candidate &&
                <>
                    <PageIntro
                    title={candidate?.name} subTitle={candidate?.title}
                    description={content.description}
                    />
                    <div className="tag-line">
                        <Avatar imgSrc={``} />
                        <Tag label={candidate?.employmentType}/>
                        {<Tag label={`${candidate?.hoursPerWeek} hrs`}/>}
                        {candidate?.onSite && <Tag label={`${candidate?.onSite}`}/>}
                        {candidate?.payRate && <Tag label={`$ ${candidate?.payRate} max`}/>}
                    </div>
                </>
            }
            <Footer />
        </Page>
    )
}
