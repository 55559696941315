
//Sorts Jobs by asc type (hot first) and desc id (most recent first)
export const job_sort = (jobs) => {
    jobs.sort((a, b) => {
        if (a.type === b.type) {
          return b.id - a.id;  // Sort by id descending
        }
        return a.type - b.type;  // Prioritize type=1
      });
}

const statusToCategoryMapping = {
    "Internally Submitted": "submitted",
    "Reviewed by sales": "submitted",
    "Candidats acceptés": "submitted",
    "Client Submission": "inprocess",
    "Interview Scheduled": "inprocess",
    "Placed": "accepted",
    "Offer Extended": "accepted",
    "Client Rejected": "refused",
    "Sales Rep Rejected": "refused",
    "Offer Rejected": "refused",
    "Candidate Not Interested": "refused"
};

export const classifyStatus = (job) =>{
    job.submissionsCount = {};
    if (job.submissions.total > 0) {
        job.submissions.data = job.submissions.data.map(submission => {
            submission.category = statusToCategoryMapping[submission.status];
            job.submissionsCount[submission.category] = (job.submissionsCount[submission.category] || 0) + 1;
            return submission;
        });
    }
    return job;
}

export const prepareJobs = (jobs) => {
    job_sort(jobs)
    jobs.map((job) => classifyStatus(job))
    return jobs
}