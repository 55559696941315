import React from 'react';
import './InputSuggestion.scss';
import PropTypes from "prop-types";

/**
 * Input Suggestion component
 */
export const InputSuggestion = ({sKey, icon, text, onClick}) => {
    return (
        <div className={'input-suggestion'} onClick={() => onClick(sKey)} tabIndex={0}>
            <div className={'bg-gradient'}/>
            <div className={'icon-wrapper'}>
                <div className={'icon-inner-wrapper'}>
                    <i className={icon}></i>
                </div>
            </div>
            <div className={'text-wrapper'}>
                <span className={'text'}>{text}</span>
            </div>
        </div>
    )
}

InputSuggestion.propTypes = {
    /**
     * Suggestion icon:
     */
    icon: PropTypes.string,
    /**
     * Suggestion text
     */
    text: PropTypes.string
};