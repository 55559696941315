import React from 'react';
import "./Footer.scss";

/**
 * Footer component for main UI
 */
export const Footer = () => {
    return (
        <div className={"footer-bg"}></div>
    );
}