import {fetchStream} from "../../../apis/getRequest";

export const getJobInfo = async ({userQueries, setUserQueries, newQuery, fetchParam, onSourceMessage, onSourceOpen, onSourceClose, onSourceError}) => {
    setUserQueries([...userQueries, newQuery])
    try {
        await fetchStream({
            url: `${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/${fetchParam.jobId}/${fetchParam.info}`,
            token: fetchParam.token,
            onmessage: onSourceMessage,
            onopen: onSourceOpen,
            onclose: onSourceClose,
            onSourceError: onSourceError
        })
    } catch (e) {
        console.error(e)
    }
}