import React, {useContext} from 'react';
import "./Home.scss";
import content from "./Home.json";
import {UserContext} from "../../context/UserContext";
import {PageIntro} from "../../components/page-intro/PageIntro";
import {SalesDashboard} from "../../components/chat/SalesDashboard";
import {RecruiterDashboard} from "../../components/chat/RecruiterDashboard";
import {Page} from "../../layouts/Page";

/**
 * Home Page
 */
export const Home = () => {
    const {user} = useContext(UserContext)
    const checkGroup = (groupName) => user?.groups.some(group => group.name === groupName)

    return (
        <Page id={"homepage-wrapper"}>
            <PageIntro title={content.title} subTitle={user?.name} />
            { checkGroup('directeur de compte') && <SalesDashboard user={user}/> }
            { checkGroup('recruteur') && <RecruiterDashboard user={user}/> }
        </Page>
    )
}