import React, {useContext, useState} from 'react';
import "./Jobs.scss";
import content from "./Jobs.json";
import {UserContext} from "../../context/UserContext";
import { useNavigate } from 'react-router-dom';
import {fetchDirect} from "../../apis/getRequest";
import {PageIntro} from "../../components/page-intro/PageIntro";
import {Page} from "../../layouts/Page";
import {prepareJobs} from "./helpers/JobsPrepare";
import {JobTable} from "./components/JobTable";

/**
 * Jobs Page
 */
export const Jobs = () => {
    const { getToken } = useContext(UserContext);
    const [jobs, setJobs] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(true)
    const navigate = useNavigate()
    const [jobTypes, setJobTypes] = useState([1,2,3])

    function loadJob(){
         const params = {
            "job_types": jobTypes.join(",")
        }
        return fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/`, getToken(), params)
        .then((response) => {
            const data = response.data
            return prepareJobs(data)
        })
    }

    const handleRowSelect = (jobId) => {
        navigate('/jobs/' + jobId);
    }

    return (
        <Page id={"jobs-page"}>
            <PageIntro
                title={content.title}
                subTitle={content.subTitle}
                description={content.description}
                variant={"secondary"}
            />
            <JobTable
                loadJobsFunc={loadJob}
                jobs={jobs}
                handleRowSelect={handleRowSelect}
                loadingStatus={loadingStatus}
                displayFilters={true}
            />
        </Page>
    )
}
