import React, {useEffect} from 'react';

export default function useMainInputRef() {
    const inputRef = React.createRef()

    useEffect(() => {
        inputRef.current.focus()
    }, [inputRef])

    return inputRef
}