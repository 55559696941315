
import {Page} from "../../layouts/Page";
import {ResizablePane} from "../../components/resizable-pane/ResizablePane";
import React from "react";
import {LeftSection} from "./left-section/LeftSection";
import {RightSection} from "./right-section/RightSection";

export const CVFormatting = () => {
    return (
        <Page id={"cv-formatting"} splitScreen={true}>
            <ResizablePane initialSize={'50%'} minSize={'30%'}>
                <LeftSection/>
            </ResizablePane>
            <ResizablePane grow={true} minSize={'30%'}>
                <RightSection/>
            </ResizablePane>
        </Page>
    )
}