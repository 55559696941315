import * as PropTypes from "prop-types";
import React from "react";
import content from '../Contract.json'

export function PlacementInfo(props) {
    return (
        <div className="candidate-info-grid">
            <div>
                <h3>{content.labels.status}&nbsp;</h3>
                {props.candidateInfo?.status || "NA"}
            </div>
            <div>
                <h3>{content.labels.submissionID}&nbsp;</h3>
                {props.candidateInfo?.submission_id}
            </div>
            <div>
                <h3>{content.labels.typeOfContract}&nbsp;</h3>
                {props.candidateInfo?.employment_type || "NA"}
            </div>
        </div>
    )
}

PlacementInfo.propTypes = {candidateInfo: PropTypes.shape({})};
