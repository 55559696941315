import React from 'react';
import "./Background.scss";

/**
 * Background component for main UI
 */
export const Background = ({opacity = 1}) => {
    return (
        <div className={"background-wrapper"} style={{opacity: opacity}}></div>
    );
}