import React from "react";

export const Page = ({children, id, fullScreen = false, splitScreen = false, chat = false, ...props}) => {
    return (
        <main id={id} data-fullscreen={fullScreen || splitScreen} data-has-chat={chat} {...props} >
            {splitScreen ?
                <div className={'split-screen-layout'}>
                    {children}
                </div>
                :
                <>
                    {children}
                </>
            }
        </main>
    );
}