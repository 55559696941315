import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../../../context/UserContext";
import { Avatar } from '../../../../components/avatar/Avatar.jsx';
import {Table} from "../../../../components/table/Table.jsx";
import {fetchDirect} from "../../../../apis/getRequest";
import { TabPanelTitle } from '../AdminHome.jsx';
import bhlogo from '../../../../assets/bh_logo.png'; 
import { InputField } from '../../../../components/input-field/InputField.jsx';
import { postRequest } from '../../../../apis/postRequest.js';


export const IntegrationsMappingTable = ({users}) => {

    const { getToken } = useContext(UserContext);
    const [bhUsers, setBhUsers] = useState([]);
    const [suggestions, setSuggestions] = useState([])
    const [inputValues, setInputValues] = useState({})
    const [isInputSelected, setIsInputSelected] = useState(false)

    useEffect(() => {
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/bullhorn/users/`, getToken())
        .then((response) => {
            setBhUsers(response.data)
            users.filter(usr => usr.integrations.length > 0).forEach(usr => {
                const bhInt = usr.integrations.filter(int => int.name == 'bullhorn')
                if(bhInt.length > 0){
                    const bhUserId = bhInt[0].integration_id
                    response.data.filter((bU => bU.id == bhUserId)).forEach((bU)=>{
                        setInputValues(prev => ({ ...prev, [usr.id]: suggText(bU)}))
                    })
                }
            })
        })
        .catch((e) => {console.error(e)})
    }, []);

    const suggText = (bhUser) => `${ bhUser?.id} - ${bhUser?.firstName} ${bhUser?.lastName}`


    useEffect(() => {
        const suggestionList= []
        bhUsers?.forEach((bhuser) => {
            suggestionList.push({
                bhuser_id: bhuser?.id,
                text: suggText(bhuser)
            })
        })
        setSuggestions(suggestionList)
    }, [bhUsers]);

    const onClearInput = (id) => {
       
    }
        

    const handleOnInputChange = (e, id) => {
        setInputValues(prev => ({ ...prev, [id]: e.target.value}))
    }

    const handleOnSuggestionClick = (suggestion, id) => {
        if (!suggestion?.text) return
        window.scrollTo(0, 0)

        const service = 'recruitment/bullhorn/users/mapping/add'
        const data = {
            'user_id': id,
            'external_id': suggestion.bhuser_id
        }
        postRequest({url:`${process.env.REACT_APP_API_HOSTNAME}/${service}`, token:getToken(), data:data})
        .then((response) =>{
            setInputValues(prev => ({ ...prev, [id]: suggestion.text}))
            setIsInputSelected(true)
        })
    }

    return (
        <>
            <TabPanelTitle>
                <Avatar imgSrc={bhlogo}/>
                <h4>Bullhorn</h4>
            </TabPanelTitle>
            <Table state={ users?.length <= 0  ? "loading" : "default"}>
                <Table.Head numOfColumns={5}>
                    <Table.Header></Table.Header>
                    <Table.Header>ID</Table.Header>
                    <Table.Header>NOM</Table.Header>
                    <Table.Header>EMAIL</Table.Header>
                    <Table.Header>UTILISATEUR BH</Table.Header>
                </Table.Head>
                <Table.Body>
                    { users?.map((user, index) => (
                        <Table.Row index={index} key={"table-row-" + index} id={user?.id}>
                            <Table.Cell>
                                <Avatar
                                    initial={user?.name?.charAt(0)}
                                    imgSrc={user?.profile_pic && `data:image/jpeg;base64,${user?.profile_pic}`}
                                />
                            </Table.Cell>
                            <Table.Cell>{user?.id}</Table.Cell>
                            <Table.Cell>{user?.name}</Table.Cell>
                            <Table.Cell>{user?.email}</Table.Cell>
                            <Table.Cell>
                            <InputField
                                id={`bhuserid-${user?.id}`}
                                value={inputValues[user?.id]}
                                variant={'select'}
                                suggestions={suggestions}
                                autoComplete={"off"}
                                onChange={(e) =>handleOnInputChange(e. user?.id)}
                                handleOnSuggestionClick={(suggestion) => handleOnSuggestionClick(suggestion, user?.id)}
                                clearField={() => onClearInput(user?.id)}
                                loading={suggestions.length == 0}
                            />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer />
            </Table>
        </>
    )

}