import React, {useState} from "react";

export const LayoutContext = React.createContext(undefined);


export const LayoutProvider = ({ children }) => {
    const [menuLinks, setMenuLinks] = useState(undefined)

    return (
        <LayoutContext.Provider value={{menuLinks, setMenuLinks}}>
            {children}
        </LayoutContext.Provider>
    )
}