import React, {useContext, useState} from "react";
import content from '../../Meeting.json'
import {Spinner} from "../../../../components/spinner/Spinner";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {patchRequest} from "../../../../apis/postRequest";
import {UserContext} from "../../../../context/UserContext";

export const TranscriptSummary = ({meetingId, meetingSubject, transcriptSummary}) => {
    const { getToken } = useContext(UserContext)
    const [newTranscriptSummary, setNewTranscriptSummary] = useState(transcriptSummary[0])

    const handleOnChange = (e) => {
        if (!e.target.innerText) return
        if (newTranscriptSummary === e.target.innerText) return

        //update changes to transcript summary by users
        patchRequest({
            url: `${process.env.REACT_APP_API_HOSTNAME}/recruitment/interviews/${meetingId}`,
            token: getToken(),
            data: {"generated_notes": e.target.innerText}
        }).catch((e) => {console.error(e)})

        setNewTranscriptSummary(e.target.innerText)
    }

    return (
        <div className={"panel-layout-container"}>
            <div className={"panel-header"} title={meetingSubject}>
                <span className={"panel-header-title"}>{meetingSubject}</span>
            </div>
            <div className={"transcript-summary-wrapper"}>
                {transcriptSummary && transcriptSummary[0] ?
                    <>
                        <h2 className={"transcript-header"}>{content.transcriptSummary.sectionName}</h2>
                        <span
                            className={"transcript-summary"}
                            onBlur={handleOnChange}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                        >
                            <Markdown remarkPlugins={[remarkGfm]}>{transcriptSummary[0]}</Markdown>
                        </span>
                    </>
                    :
                    <div className={"transcript-summary-spinner"}>
                        <Spinner visible={transcriptSummary}/>
                    </div>
                }
            </div>
        </div>
    )
}