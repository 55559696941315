import React, {useContext, useEffect, useState} from 'react';
import "./AdminHome.scss";
import content from "./AdminHome.json";
import {fetchDirect} from "../../../apis/getRequest";
import {UserContext} from "../../../context/UserContext";
import {PageIntro} from "../../../components/page-intro/PageIntro";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { UsersTable } from './components/UsersTable.jsx';
import { IntegrationsMappingTable } from './components/IntegrationsMappingTable.jsx';

import {Page} from "../../../layouts/Page";

/**
 * AdminHome Page
 */
export const AdminHome = () => {
    const { getToken } = useContext(UserContext)
    const [users, setUsers] = useState([])

    useEffect(() => {
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/users/`, getToken())
        .then((response) => {
            setUsers(response)
        })
        .catch((e) => {console.error(e)})
    }, [getToken])

    return (
        <Page>
            <PageIntro
                title={content.title}
                subTitle={content.subTitle}
                description={content.description}
                variant={"secondary"}
            />
            <Tabs>
                <TabList>
                    <Tab><i className="fa-solid fa-user-gear"></i>Utilisateurs</Tab>
                    <Tab><i className="fa-solid fa-plug-circle-check"></i>Intégrations</Tab>
                </TabList>
                <TabPanel>
                    <UsersTable users={users}/>
                </TabPanel>
                <TabPanel>

                    <IntegrationsMappingTable users={users}/>
                </TabPanel>
            </Tabs>
        </Page>
    )
}

export const TabPanelTitle = ({children}) =>{
    return <div className="title">{children}</div>
}