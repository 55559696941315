import {formatAddress} from "./formatAddress";

export const formatPlacements = (response) => {
    let placements = []
    if (!response?.data) return placements
    response?.data.forEach((placement) =>
        placements.push({
            candidate_firstname: placement?.candidate?.firstName,
            candidate_lastname: placement?.candidate?.lastName,
            client_name: placement?.jobOrder?.clientCorporation?.name,
            candidate_email: placement?.candidate?.email,
            client_address: formatAddress(placement?.jobOrder?.address)?.trim(),
            submission_id: placement?.id,
            status: placement?.status,
            employment_type: placement?.candidate?.employeeType[0],
            hour_rate: placement?.payRate,
            hours_per_week: placement?.jobOrder.hoursPerWeek,
            job_title: placement?.jobOrder.title,
            owner_name: placement?.owners?.data[0]?.firstName || placement?.owners?.data[0]?.lastName ? placement?.owners?.data[0]?.firstName + ` ` + placement?.owners?.data[0]?.lastName : "",
            starting_date: undefined,
            end_date: undefined
        })
    )
    return placements
}