import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import "./Login.scss";
import content from "./Login.json"
import {Background} from "../../components/bg/Background";
import {InputField} from "../../components/input-field/InputField";
import {Button} from "../../components/button/Button";
import {Footer} from "../../components/footer/Footer";
import {useNavigate} from "react-router-dom";
import { OAuth2Client, generateCodeVerifier } from '@badgateway/oauth2-client';

/**
 * Login Page
 */
export const Login = () => {
    const navigate = useNavigate()
    const [userLogin, setUserLogin] = useState({email: "", pwd: ""})
    const location = useLocation() // Hook to access location object
    const queryParams = new URLSearchParams(location.search) // Parse query parameters
    const errorMessage = queryParams.get('error') // Get the 'error' parameter


    const handleOnInputChange = (e, inputType) => {
        setUserLogin({...userLogin, [inputType]: e.target.value})
    }

    const handleOnSubmit = (e) => {
        e.preventDefault()
        navigate('/')
    }

    const microsoftAuth = async (e) => {
        e.preventDefault();
        const client = new OAuth2Client({
            server: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/oauth2/v2.0`,
            clientId: `${process.env.REACT_APP_AZURE_APP_CLIENT_ID}`,
            discoveryEndpoint: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/v2.0/.well-known/openid-configuration`
        });

        const codeVerifier = await generateCodeVerifier();
        localStorage.setItem('codeVerifier', codeVerifier)
        document.location = await client.authorizationCode.getAuthorizeUri({
            redirectUri: `${process.env.REACT_APP_HOSTNAME}/oauth2-redirect`,
            codeVerifier: codeVerifier,
            scope: [`api://${process.env.REACT_APP_AZURE_APP_CLIENT_ID}/user_impersonation`],
        })
    }

    return (
        <div className={"login-page"}>
            <Background />
            <FormWrapper>
                <LoginHeader />
                <form className={"login-form"}>
                    { errorMessage && (
                        <h4 style={{ color: 'red' }}>
                        Error: {decodeURIComponent(errorMessage)}
                        </h4>
                    )}

                    <InputField
                        id={"email"}
                        label={content.labels.email}
                        type={"email"}
                        isRequired={true}
                        value={userLogin.email}
                        onChange={(e) => handleOnInputChange(e, "email")}
                    />
                    <InputField
                        id={"password"}
                        label={content.labels.password}
                        type={"password"}
                        isRequired={true}
                        value={userLogin.pwd}
                        onChange={(e) => handleOnInputChange(e, "pwd")}
                    />
                    <Button
                        variant={"primary"}
                        type={"submit"}
                        size={"large"}
                        label={content.labels.login}
                        onClick={handleOnSubmit}
                    />
                    <Button
                        variant={"tertiary"}
                        type={"submit"}
                        size={"large"}
                        label={content.labels.loginMicrosoft}
                        icon={"fa-brands fa-microsoft"}
                        onClick={microsoftAuth}
                    />
                </form>
            </FormWrapper>
            <Footer />
        </div>
    )
}

function LoginHeader() {
    return (
        <div className={"form-header"}>
            <div className={"bg-gradient"}></div>
            <div className={"logo-wrapper"}>
                <h1 className={"logo-title"}>{content.title}</h1>
                <h2 className={"logo-subtitle"}>{content.subTitle}</h2>
            </div>
        </div>
    )
}

function FormWrapper({children}) {
    return (
        <div className={"form-wrapper"}>
            <div className={"form-container"}>
                {children}
            </div>
        </div>
    )
}