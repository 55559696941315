import React, { useEffect, useState } from 'react';
import "./Checkbox.scss";
import { Oval } from 'react-loader-spinner'

/**
 * Checkbox component for main UI
 */
export const Checkbox = ({ defaultChecked, onChange, loading=false}) => {

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(defaultChecked)
    }, []);
    

    const switchCheck = () =>{
        onChange(!isChecked)
        setIsChecked(!isChecked)
    }

    return (
        <label className="checkbox-container">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={switchCheck}
                className="hidden-checkbox"
            />
            {!loading && <span className={isChecked ? 'checkbox checked' : 'checkbox'}></span>}
            {<Oval visible={loading} height="20" width="20" color="#7B2AA1" strokeWidth="6" secondaryColor="#fff" ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass=""/>}
        </label>
    );
}