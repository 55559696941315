import React from 'react';
import "./ChatActionButton.scss";

/**
 * Action button component for bubbles
 */
export const ChatActionButton = ({icon, action, ...props}) => {
    return (
        <div className={"action-btn-wrapper"}>
            <button className={"chat-action-btn"} {...props}>
                <i className={icon}></i>
            </button>
            <span className={"action"}>{action}</span>
        </div>
    );
}