import React from 'react';
import PropTypes from 'prop-types';
import "./Tag.scss";

/**
 * Tag component for main UI
 */
export const Tag = ({label, variant}) => {
    return (
        <div className={"tag"} data-variant={variant}>
            <label>{label}</label>
        </div>
    );
}

Tag.propTypes = {
    /**
     * Types of buttons:
     */
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', ''])

}