import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {OAuth2RedirectHandler} from "./components/security/OAuth2RedirectHandler.jsx";
import {ProtectedRoute} from "./components/security/ProtectedRoute.jsx";
import {Login} from "./pages/login/Login.jsx";
import {Home} from "./pages/home/Home.jsx";
import {Hr} from './pages/hr/Hr.jsx';
import {Jobs} from "./pages/jobs/Jobs.jsx";
import {Job} from "./pages/job/Job.jsx";
import {Layout} from "./layouts/Layout";
import {Contract} from "./pages/contract/Contract.jsx";
import {Sandbox} from "./pages/sandbox/Sandbox.jsx";
import { AdminHome } from './pages/admin/home/AdminHome.jsx';
import {Meetings} from "./pages/meetings/Meetings.jsx";
import {Meeting} from "./pages/meeting/Meeting.jsx";
import { RecruiterChat } from './pages/recruitment/chat/RecruiterChat.jsx';
import { Candidate } from './pages/candidate/Candidate.jsx';
import { Candidates } from './pages/candidates/Candidates.jsx';
import {CVFormatting} from "./pages/cv-formatting/CVFormatting.jsx";

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/oauth2-redirect" element={<OAuth2RedirectHandler />} />
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<ProtectedRoute component={Home} />} />
                <Route path="/recruitement" element={<ProtectedRoute component={RecruiterChat} />} />
                <Route path="/jobs" element={<ProtectedRoute component={Jobs}/>}/>
                <Route path="/jobs/:jobId" element={<ProtectedRoute component={Job}/>}/>
                <Route path="/candidates" element={<ProtectedRoute component={Candidates}/>}/>
                <Route path="/candidates/:candidateId" element={<ProtectedRoute component={Candidate}/>}/>
                <Route path="/hr" element={<ProtectedRoute component={Hr} />}/>
                <Route path="/contract" element={<ProtectedRoute component={Contract} />}/>
                <Route path="/meetings" element={<ProtectedRoute component={Meetings} />}/>
                <Route path="/meetings/:meetingId" element={<ProtectedRoute component={Meeting} />}/>
                <Route path="/cv-formatting" element={<ProtectedRoute component={CVFormatting} />}/>
                <Route path="/sandbox" element={<ProtectedRoute component={Sandbox} />}/>
                <Route path="/administration" element={<ProtectedRoute component={AdminHome} />}/>
            </Route>
        </Routes>
      </Router>
  )
}

export default App;
