import React, {useContext, useEffect, useRef, useState} from 'react';
import { useParams } from 'react-router-dom';
import "./Job.scss";
import content from "./Job.json";
import {UserContext} from "../../context/UserContext.js";
import {fetchDirect} from "../../apis/getRequest";
import {PageIntro} from "../../components/page-intro/PageIntro";
import { Spinner } from '../../components/spinner/Spinner.jsx';
import {Page} from "../../layouts/Page";
import {JobActionBtns} from "./components/JobActionBtns";
import {PageAnchor} from "../../layouts/PageAnchor";
import {chatStream} from "../../helpers/chatStream";
import {getJobInfo} from "./helpers/getJobInfo";
import {JobCard} from "./components/JobCard";
import {JobInfoCard} from "./components/JobInfoCard";
import {Tag} from "../../components/tag/Tag";
import {Avatar} from "../../components/avatar/Avatar.jsx";
import {Footer} from "../../components/footer/Footer";

/**
 * Job Page
 */
export const Job = () => {
    const { getToken } = useContext(UserContext)
    const { jobId } = useParams()
    const newResponse = useRef()

    const [responses, setResponses] = useState([])
    const [job, setJob] = useState()
    const [loadingStatus, setLoadingStatus] = useState(0)
    const [userQueries, setUserQueries] = useState([])
    const [isResponseDone, setIsResponseDone] = useState(false)
    const [usersPics, setUsersPics] = useState({})

    const {
        onSourceOpen, onSourceMessage, onSourceError, onSourceClose
    } = chatStream({
        responses: responses,
        setResponses: setResponses,
        setIsResponseDone: setIsResponseDone,
        newResponse: newResponse
    })

    const formatDate = (timestamp) => {
        const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'}
        return new Intl.DateTimeFormat('ca-FR', dateFormat).format(timestamp)
    }

    useEffect(() =>{
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/users/pictures`, getToken())
        .then((pictures) => {
            const usersPics = {}
            pictures?.forEach(p => usersPics[p.integration_id] = p.profile_pic )
            setUsersPics(usersPics)
        });
    },[getToken])

    useEffect(() => {
        if(loadingStatus === 0){
            setLoadingStatus(1)
            fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/${jobId}`, getToken())
            .then((response) => {
                setJob(response)
                setLoadingStatus(2)
            })
            .catch((e) => {console.error(e)})
        }

    }, [jobId, getToken, setJob, loadingStatus, setLoadingStatus])

    const getLinkedinPost = async () => {
        await getJobInfo({
            userQueries: userQueries, setUserQueries: setUserQueries,
            newQuery: {title: 'Publication LinkedIn', icon: 'fa-brands fa-linkedin'},
            fetchParam: {info: "linkedin", jobId: jobId, token: getToken()},
            onSourceOpen: onSourceOpen,
            onSourceMessage: onSourceMessage,
            onSourceError: onSourceError,
            onSourceClose: onSourceClose
        })
    }

    const getInterviewQuestions = async () => {
         await getJobInfo({
             userQueries: userQueries, setUserQueries: setUserQueries,
             newQuery: {title: `Questions`, icon: 'fa-light fa-sparkles'},
             fetchParam: {info: "interview", jobId: jobId, token: getToken()},
             onSourceOpen: onSourceOpen,
             onSourceMessage: onSourceMessage,
             onSourceError: onSourceError,
             onSourceClose: onSourceClose
         })
    }

    const getCandidates = async () => {
         await getJobInfo({
             userQueries: userQueries, setUserQueries: setUserQueries,
             newQuery: {title: `Meilleurs candidats`, icon: 'fa-light fa-sparkles'},
             fetchParam: {info: "candidates", jobId: jobId, token: getToken()},
             onSourceOpen: onSourceOpen,
             onSourceMessage: onSourceMessage,
             onSourceError: onSourceError,
             onSourceClose: onSourceClose
         })
    }

    return (
        <Page id="job-page">
            <Spinner visible={job === undefined} />
            {job &&
                <>
                    <PageIntro
                    title={job?.clientCorporation.name} subTitle={job?.title}
                    description={content.description}
                    />
                    <div className="tag-line">
                        <Avatar imgSrc={`data:image/jpeg;base64,${usersPics[job?.owner.id]}`} />
                        <Tag label={job?.employmentType} />
                        <Tag label={`${job?.hoursPerWeek} hrs`} />
                        {job?.onSite && <Tag label={`${job?.onSite}`} />}
                        {(job?.payRate || job?.payRate !== 0) && <Tag label={`$ ${job?.payRate} max`} />}
                    </div>
                </>
            }
            {userQueries.length > 0 &&
                <div className={"action-responses"}>
                    {userQueries.map((userQuery, index) =>
                        <JobInfoCard
                            key={`action-response-${index}`}
                            index={index}
                            userQuery={userQuery}
                            responses={responses}
                            newResponse={newResponse}
                            userQueriesLength={userQueries.length}
                        />
                    )}
                </div>
            }
            {job &&
                <>
                    <JobCard job={job} />
                    <JobActionBtns
                        isResponseDone={isResponseDone}
                        queriesLength={userQueries.length}
                        getLinkedinPost={getLinkedinPost}
                        getCandidates={getCandidates}
                        getInterviewQuestions={getInterviewQuestions}
                    />
                </>
            }
            <Footer />
        </Page>
    )
}
