import {InputField} from "../../../components/input-field/InputField";
import React from "react";
import * as PropTypes from "prop-types";
import content from '../Contract.json'
import {Textarea} from "../../../components/textarea/Textarea";
import {Button} from "../../../components/button/Button";

export function ContractForm(props) {
    return (
        <form>
            <fieldset className={"contract-form-section"} data-variant={"2-col"}>
                <h2 className={"contract-section-header"}>{content.candidate.header}</h2>
                <div className={"form-section-inputs"}>
                <span className={"form-col"}>
                    <InputField
                        id={"candidate_firstname"}
                        label={content.candidate.firstName}
                        variant={"secondary"}
                        value={props.candidateInfo?.candidate_firstname || ""}
                        onChange={(e) => props.handleOnChange(e, "candidate_firstname")}
                        onBlur={(e) => props.handleOnBlur(e, "candidate_firstname")}
                    />
                    <InputField
                        id={"candidate_lastname"}
                        label={content.candidate.lastName}
                        variant={"secondary"}
                        value={props.candidateInfo?.candidate_lastname || ""}
                        onChange={(e) => props.handleOnChange(e, "candidate_lastname")}
                        onBlur={(e) => props.handleOnBlur(e, "candidate_lastname")}
                    />
                </span>
                    <InputField
                        id={"candidate_email"}
                        label={content.candidate.email}
                        variant={"secondary"}
                        value={props.candidateInfo?.candidate_email || ""}
                        onChange={(e) => props.handleOnChange(e, "candidate_email")}
                        onBlur={(e) => props.handleOnBlur(e, "candidate_email")}
                    />
                    <InputField
                        id={"candidate_company_name"}
                        label={content.candidate.companyName}
                        variant={"secondary"}
                        value={props.candidateInfo?.candidate_company_name || ""}
                        onChange={(e) => props.handleOnChange(e, "candidate_company_name")}
                        onBlur={(e) => props.handleOnBlur(e, "candidate_company_name")}
                    />
                </div>
                <div className={"form-section-inputs"}>
                    <InputField
                        id={"candidate_company_address"}
                        label={content.candidate.companyAddress}
                        variant={"secondary"}
                        value={props.candidateInfo?.candidate_company_address || ""}
                        onChange={(e) => props.handleOnChange(e, "candidate_company_address")}
                        onBlur={(e) => props.handleOnBlur(e, "candidate_company_address")}
                    />
                    <InputField
                        id={"candidate_company_tps"}
                        label={content.candidate.tps}
                        variant={"secondary"}
                        value={props.candidateInfo?.candidate_company_tps || ""}
                        onChange={(e) => props.handleOnChange(e, "candidate_company_tps")}
                        onBlur={(e) => props.handleOnBlur(e, "candidate_company_tps")}
                    />
                    <InputField
                        id={"candidate_company_tvq"}
                        label={content.candidate.tvq}
                        variant={"secondary"}
                        value={props.candidateInfo?.candidate_company_tvq || ""}
                        onChange={(e) => props.handleOnChange(e, "candidate_company_tvq")}
                        onBlur={(e) => props.handleOnBlur(e, "candidate_company_tvq")}
                    />
                </div>
            </fieldset>
            <fieldset className={"contract-form-section"} data-variant={"2-col"}>
                <h2 className={"contract-section-header"}>{content.mandate.header}</h2>
                <div className={"form-section-inputs"}>
                    <InputField
                        label={content.mandate.director}
                        variant={"secondary"}
                        readOnly={true}
                        value={props.candidateInfo?.owner_name || ""}
                    />
                    <InputField
                        label={content.mandate.client}
                        variant={"secondary"}
                        readOnly={true}
                        value={props.candidateInfo?.client_name || ""}
                    />
                    <InputField
                        label={content.mandate.clientAddress}
                        variant={"secondary"}
                        readOnly={true}
                        value={props.candidateInfo?.client_address || ""}
                    />
                    <span className={"form-col"}>
                    <InputField
                        label={content.mandate.supervisorName}
                        variant={"secondary"}
                        value={props.candidateInfo?.client_supervisor || ""}
                        onChange={(e) => props.handleOnChange(e, "client_supervisor")}
                        onBlur={(e) => props.handleOnBlur(e, "client_supervisor")}
                    />
                    <InputField
                        label={content.mandate.supervisorTitle}
                        variant={"secondary"}
                        value={props.candidateInfo?.client_supervisor_title || ""}
                        onChange={(e) => props.handleOnChange(e, "client_supervisor_title")}
                        onBlur={(e) => props.handleOnBlur(e, "client_supervisor_title")}
                    />
                </span>
                    <span className={"form-col"}>
                    <InputField
                        label={content.mandate.hourlyRate}
                        variant={"secondary"}
                        value={props.candidateInfo?.hour_rate || ""}
                        onChange={(e) => props.handleOnChange(e, "hour_rate")}
                        onBlur={(e) => props.handleOnBlur(e, "hour_rate")}
                    />
                    <InputField
                        label={content.mandate.hoursPerWeek}
                        variant={"secondary"}
                        value={props.candidateInfo?.hours_per_week || ""}
                        onChange={(e) => props.handleOnChange(e, "hours_per_week")}
                        onBlur={(e) => props.handleOnBlur(e, "hours_per_week")}
                    />
                </span>
                    <span className={"form-col"}>
                    <InputField
                        label={content.mandate.startDate}
                        variant={"secondary"}
                        value={props.candidateInfo?.starting_date || ""}
                        onChange={(e) => props.handleOnChange(e, "starting_date")}
                        onBlur={(e) => props.handleOnBlur(e, "starting_date")}
                    />
                    <InputField
                        label={content.mandate.endDate}
                        variant={"secondary"}
                        value={props.candidateInfo?.end_date || ""}
                        onChange={(e) => props.handleOnChange(e, "end_date")}
                        onBlur={(e) => props.handleOnBlur(e, "end_date")}
                    />
                </span>
                </div>
                <div className={"form-section-inputs"}>
                    <InputField
                        label={content.mandate.jobTitle}
                        variant={"secondary"}
                        value={props.candidateInfo?.job_title || ""}
                        onChange={(e) => props.handleOnChange(e, "job_title")}
                        onBlur={(e) => props.handleOnBlur(e, "job_title")}
                    />
                    <div className={"textarea-btn"}>
                        <Textarea
                            label={content.mandate.tasksSummary}
                            variant={"secondary"}
                            placeholder={content.placeholder.tasksPlaceHolder}
                            value={props.candidateInfo?.job_tasks_summary || ""}
                            onChange={(e) => props.handleOnChange(e, "job_tasks_summary")}
                            onBlur={(e) => props.handleOnBlur(e, "job_tasks_summary")}
                            style={{minHeight: '200px'}}
                        />
                        <Button
                            variant={'tertiary'}
                            label={content.mandate.regenerateBtnLabel}
                            icon={content.mandate.regenerateBtnIcon}
                            onClick={props.handleOnRegenerateClick}
                            state={props.isRegenerating ? "loading" : "default"}
                        />
                    </div>
                </div>
            </fieldset>
        </form>
    )
}

ContractForm.propTypes = {candidateInfo: PropTypes.shape({})};
