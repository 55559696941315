import React from 'react';
import "./Popup.scss";

/**
 * Popup component for main UI
 */
export const Popup = ({children, state = 'close'}) => {
    return (
        <div className={"page-popup-wrapper"} data-state={state}>
            <div className={"popup-inner-wrapper"}>
                {children}
            </div>
        </div>
    );
}