export const getNameSuggestions = (candidates, inputValue) => {
    let suggestionList = []
    if (!candidates || candidates.length <= 0) return suggestionList
    const filteredNames = candidates.filter((candidate) => {
        const name = candidate?.name.trim()
        if (name.length <= 0) return
        return(
            name?.toLowerCase().startsWith(inputValue.trim().toLowerCase()) ||
            `${name}`?.toLowerCase().startsWith(inputValue.trim().toLowerCase())
        )
    })
    filteredNames.forEach((candidate) => {
        suggestionList.push({
            text: `${candidate?.name.trim()}`,
            id: candidate?.id
        })
    })
    return suggestionList
}


export const getJobSuggestions = (jobs, inputValue) => {
    let suggestionList = []
    if (!jobs || jobs.length <= 0) return suggestionList
    const filteredJobs = jobs.filter((job) => {
        const title = job?.title.trim()
        if (title.length <= 0) return
        return(
            title?.toLowerCase().startsWith(inputValue.trim().toLowerCase()) ||
            `${title}`?.toLowerCase().startsWith(inputValue.trim().toLowerCase())
        )
    })
    filteredJobs.forEach((job) => {
        suggestionList.push({
            text: `${job?.title.trim()}`,
            id: job?.id
        })
    })
    return suggestionList
}