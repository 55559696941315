import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './ChatBubble.scss';
import {Avatar} from "../../avatar/Avatar";
import {ChatActionButton} from "../chat-action-button/ChatActionButton";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

/**
 * Primary UI chat bubble component for user interaction
 */
export const ChatBubble = ({ variant, text, avatarInitial, avatarImage, delayDisplay = 10, reference = null, isResponseDone = false }) => {
    const [isDisplayed, setIsDisplayed] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
            setIsDisplayed(true);
        }, delayDisplay);
        return () => clearTimeout(timer);
    }, [delayDisplay]);


    const handleOnCopy = async () => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }

    return (
       <>
           {isDisplayed &&
               <div className={"wrapper-bubble"} data-variant={variant}>
                   <div className={"bubble-bg-wrapper"}>
                       <div className={"bubble-bg"}>
                           <svg className={'bubble-tip'} width="78" height="43" viewBox="0 0 78 43" xmlns="http://www.w3.org/2000/svg">
                               <path fillRule="evenodd" clipRule="evenodd" d="M78 0H38.2848C37.0211 27.7685 30.784 42.9042 0 42.9042H78V0Z"/>
                           </svg>
                       </div>
                       <div className={"bubble-content"}>
                           <Avatar initial={avatarInitial} imgSrc={avatarImage}/>
                           <div ref={reference} className={"bubble-text"} style={avatarInitial && {margin: '0 48px 0 0'}}>
                               <Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown>
                           </div>
                       </div>
                   </div>
                   {(variant === "agent-text" && isResponseDone) &&
                       <div className={"action-buttons"}>
                           <ChatActionButton
                               icon={"fa-solid fa-copy"}
                               action={"Copier"}
                               onClick={handleOnCopy}
                           />
                       </div>
                   }
                   <div className={"bubble-goo"}></div>
               </div>
           }
       </>
    );
};

ChatBubble.propTypes = {
    /**
     * Types of bubbles:
     */
    variant: PropTypes.oneOf(['user-text', 'agent-text', 'agent-loading']),
    /**
     * Chat text
     */
    icon: PropTypes.string
};