import React, {useContext, useEffect, useState} from "react";
import {ValidateInterviewSection} from "./components/ValidateInterviewSection";
import {InterviewSection} from "./components/InterviewSection";
import {UserContext} from "../../../context/UserContext";
import {patchRequest} from "../../../apis/postRequest";

export const RightSection = ({meetingId, interview, transcriptActive, showTranscriptPage}) => {
    const { getToken } = useContext(UserContext)
    const [notes, setNotes] = useState('')
    const [updatedNotes, setUpdatedNotes] = useState('')
    const [selectedJob, setSelectedJob] = useState()

    useEffect(() => {
        if (!interview?.ats_notes) return
        setNotes(interview?.ats_notes)
        setUpdatedNotes(interview?.ats_notes)
    }, [interview?.ats_notes])

    // useEffect(() => {
    //     if (!interview?.jobTitle) return
    //     setSelectedJob(interview?.jobTitle)
    // }, [interview?.jobTitle])

    const handleOnNoteChange = (e) => {
        setNotes(e.target.value)
    }

    const handleOnNoteBlur = () => {
        if (!notes) return
        if (notes === updatedNotes) return

        patchRequest({
            url: `${process.env.REACT_APP_API_HOSTNAME}/recruitment/interviews/${meetingId}`,
            token: getToken(),
            data: {"ats_notes": notes}
        }).catch((e) => {console.error(e)})

        setUpdatedNotes(notes)
    }
    return (
        <>
            {(transcriptActive) ?
                <ValidateInterviewSection
                    notes={notes}
                    setNotes={setNotes}
                    handleOnNoteChange={handleOnNoteChange}
                    handleOnNoteBlur={handleOnNoteBlur}
                />
                :
                <InterviewSection
                    selectedJob={selectedJob}
                    setSelectedJob={setSelectedJob}
                    notes={notes}
                    setNotes={setNotes}
                    handleOnClick={showTranscriptPage}
                    handleOnNoteChange={handleOnNoteChange}
                    handleOnNoteBlur={handleOnNoteBlur}
                />
            }
        </>
    )
}