import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../../../context/UserContext";
import { Avatar } from '../../../../components/avatar/Avatar.jsx';
import {Table} from "../../../../components/table/Table.jsx";
import {Checkbox} from "../../../../components/checkbox/Checkbox.jsx";
import { Button } from '../../../../components/button/Button.jsx';

import {fetchDirect} from "../../../../apis/getRequest";


export const UsersTable = ({users}) => {

    const [refresh, setRefresh] = useState(false)
    const [loadingCheckboxes, setLoadingCheckboxes] = useState({})
    const { getToken } = useContext(UserContext);



    useEffect(() => {
        const initialLoadingStates = {};
        users.forEach(user => {
            user.groups.forEach(group => {
                initialLoadingStates[`${user.id}-${group.name}`] = false;
            });
        });
        setLoadingCheckboxes(initialLoadingStates);
    }, [users]);


    const checkGroup = (groups, groupName) => groups.some(group => group.name === groupName);

    const switchGroup = (isChecked, userId, groupName) => {
        setLoadingCheckboxes(prev => ({ ...prev, [`${userId}-${groupName}`]: true }));
        console.log(loadingCheckboxes)
        const service = isChecked ? "users/groups/add" : "users/groups/remove"
        const params = {
            "user_id": userId,
            "group_name": groupName
        }
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/${service}`, getToken(), params)
        .then((response) => { 
            console.log(response) 
            setRefresh(true)
            console.log(loadingCheckboxes)
        })
        .catch((error) =>console.log(error))
        .finally(() => setLoadingCheckboxes(prev => ({ ...prev, [`${userId}-${groupName}`]: false })))
    }

    function deleteUser(userId){
        const service = "users/delete"
        const params = {
            "user_id": userId
        }
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/${service}`, getToken(), params)
        .then((response) => { console.log(response) })
    }

    const groups = [
        {'name' : 'admin', 'title' : 'ADMIN'},
        {'name' : 'recruteur', 'title' : 'RECRUTEUR'},
        {'name' : 'directeur de compte', 'title' : 'VENTE'},
        {'name' : 'rh', 'title' : 'RH'},
    ]

    return (
        <Table state={ users?.length <= 0 ? "loading" : "default"}>
            <Table.Head numOfColumns={10}>
                <Table.Header></Table.Header>
                <Table.Header>ID</Table.Header>
                <Table.Header>NOM</Table.Header>
                <Table.Header>EMAIL</Table.Header>
                <Table.Header>SOURCE</Table.Header>
                { groups.map((group) => <Table.Header key={`group-${group.title}`}>{group.title}</Table.Header>) }
                <Table.Header></Table.Header>
            </Table.Head>
            <Table.Body>
                { users?.map((user, index) => (
                    <Table.Row key={"table-row-" + index} index={index} id={user?.id}>
                        <Table.Cell>
                            <Avatar
                                initial={user?.name?.charAt(0)}
                                imgSrc={user?.profile_pic && `data:image/jpeg;base64,${user?.profile_pic}`}
                            />
                        </Table.Cell>
                        <Table.Cell>{user?.id}</Table.Cell>
                        <Table.Cell>{user?.name}</Table.Cell>
                        <Table.Cell>{user?.email}</Table.Cell>
                        <Table.Cell>
                            <img alt={""} height="25px" src="https://images.ctfassets.net/p03bi75xct27/5qAgkGIupv615synfBy48e/1103cf0b64135fda2b4932c4e3bd70c7/active-directory.png?q=80&fm=webp&w=2048"/>
                        </Table.Cell>
                        { groups.map((group, index) => {
                                return (
                                    <Table.Cell align='center' key={'table-cell-' + index}>
                                        <Checkbox 
                                            defaultChecked={checkGroup(user.groups, group.name)} 
                                            onChange={(selected)=>switchGroup(selected, user.id, group.name)}
                                            loading={loadingCheckboxes[`${user.id}-${group.name}`]}
                                            />
                                    </Table.Cell>
                                )
                        })}
                        <Table.Cell align='center'>
                            <Button
                                icon={"fa-solid fa-user-xmark"}
                                size={"small"}
                                variant={"primary"}
                                onClick={() => deleteUser(user.id)}
                            />
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
            <Table.Footer>
            </Table.Footer>
        </Table>
    )

}