import React, {useContext, useState} from "react";
import {TranscriptSummary} from "./components/TranscriptSummary";
import {DocumentViewer} from "./components/DocumentViewer";
import {CandidatesSearch} from "./components/CandidatesSearch";
import {fetchDirect} from "../../../apis/getRequest";
import {UserContext} from "../../../context/UserContext";

export const LeftSection = ({meetingId, transcriptSummary, transcriptActive, meetingSubject}) => {
    // const [docs, setDocs] = useState([])
    const { getToken } = useContext(UserContext)
    const [docs, setDocs] = useState([
       // { uri: require("../../../assets/HEARTY_CV.pdf") }
    ])
    // const docs = [
    //     { uri: "https://url-to-my-pdf.pdf" }, // Remote file
    //     { uri: require("./example-files/pdf.pdf") }, // Local File
    // ]
    const [isCVFound, setIsCVFound] = useState('')

    const findCV = (candidateId) => {
        console.log("candidateId:" + candidateId)
        //TODO: get cv of candidate

        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/candidates/${candidateId}`, getToken())
            .then((response) => {
                console.log(response)

                if (!response.resume_file) {
                    setIsCVFound('not-found')
                    return
                }
                setIsCVFound('found')
                setDocs([{ uri: response.resume_file }])
            })
            .catch((e) => {console.error(e)})
        //setDocs([])
    }

    const handelOnChangeCandidate = () => {
        setDocs([])
    }

    return (
        <>
            {(transcriptActive) ?
                <TranscriptSummary
                    meetingId={meetingId}
                    meetingSubject={meetingSubject}
                    transcriptSummary={transcriptSummary}
                />
                :
                <>
                    {docs && docs.length > 0 ?
                        <DocumentViewer
                            docs={docs}
                            meetingSubject={meetingSubject}
                            handelOnChangeCandidate={handelOnChangeCandidate}
                        />
                        :
                        <CandidatesSearch
                            meetingSubject={meetingSubject}
                            findCV={findCV}
                            isCVFound={isCVFound}
                        />
                    }
                </>
            }
        </>
    )
}