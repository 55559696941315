import React, {useEffect, useState} from "react";
import './ResizablePane.scss'

export const ResizablePane = ({children, id, initialSize = '0%', minSize="25%", maxSize="100%", grow= false}) => {
    const paneRef = React.createRef()
    const [size, setSize] = useState(initialSize)
    const [deltaSize, setDeltaSize] = useState(0)
    const [isResizing, setIsResizing] = useState(false)

    useEffect(() => {
        const savedSize = localStorage.getItem("panel-" + id)
        if (!savedSize) return

        localStorage.setItem("panel-" + id, savedSize);
        setSize(savedSize)
    }, [id, size])

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isResizing) return
            e.preventDefault()

            let newSize = deltaSize + e.movementX
            setDeltaSize(newSize)
        }

        const handleMouseUp = () => {
            if (!isResizing) return
            setIsResizing(false)
            setDeltaSize(0)

            const paneWidth = paneRef.current?.offsetWidth || 1
            const newSize = `${Math.round(100 * paneWidth / window.innerWidth)}%`

            setSize(newSize)
            localStorage.setItem("panel-" + id, newSize);
        }

        document.addEventListener("mousemove", handleMouseMove)
        document.addEventListener("mouseup", handleMouseUp)

        return () => {
            document.removeEventListener("mousemove", handleMouseMove)
            document.removeEventListener("mouseup", handleMouseUp)
        }
    }, [isResizing, deltaSize, paneRef, id]);

    const handleOnMouseDown = () => {
        setIsResizing(true)
    }

    return (
        <div
            id={id}
            ref={paneRef}
            className={"resizable-pane"}
            data-grow={grow ? "true" : "false"}
            data-resizable={isResizing}
            style={{
                minWidth: minSize,
                maxWidth: maxSize,
                width: !grow ? `calc(${size} + ${deltaSize}px)` : "initial"
            }}
        >
            {children}
            {!grow &&
                <ResizableHandle
                    isResizing={isResizing}
                    handleOnMouseDown={handleOnMouseDown}
                />
            }
        </div>
    );
}

const ResizableHandle = ({isResizing, handleOnMouseDown}) => {
    return (
        <div
            className={"resize-handlebar"}
            data-resizing={isResizing}
            onMouseDown={handleOnMouseDown}
        />
    )
}