import {Button} from "../../../components/button/Button";
import content from "../Contract.json";
import React from "react";

export const ContractBtns = ({contractURL, contractFilename, handleOnBtnClick}) => {
    return (
        <div className={"contract-btns"}>
            {contractURL && contractFilename.length > 0 &&
                <a href={contractURL} download={contractFilename}>
                    <Button
                        variant={"tertiary"}
                        label={content.labels.downloadContractBtn}
                    />
                </a>
            }
            <Button
                variant={"primary"}
                label={content.labels.generateContractBtn}
                onClick={handleOnBtnClick}
            />
        </div>
    )
}