import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

export const ProtectedRoute = ({ component: Component, groups=[] }) => {
  const { isLogged } = useContext(UserContext);
  const location = useLocation(); // Get the current location
  if (!isLogged()) {
    // Save the current location to session storage before redirecting
    sessionStorage.setItem('preAuthPath', location.pathname + location.search);
    // Redirect to the login page if not authenticated
    return <Navigate to="/login" replace />;
  }

  return <Component />;
};
