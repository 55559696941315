import React, {useEffect, useRef, useState} from 'react';
import './InputField.scss';
import PropTypes from "prop-types";
import {Button} from "../button/Button.jsx";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { Oval } from 'react-loader-spinner'


/**
 * Input field component
 */
export const InputField = ({
    id, label, variant, placeholder, type, isRequired, reference, value, suggestionPos = 'bottom', variantType,
    onSubmit, disableSubmit = false, suggestions, handleOnSuggestionClick, clearField, loading=false,
    ...props
}) => {

    const outerReference = useRef()
    const [activeIndex, setActiveIndex] = useState(0)
    const [isFocused, setIsFocused] = useState(false)
    const [listReference, setListReference] = useState()

    useEffect(() => {
        const list = document.getElementById('auto-complete-' + id)
        setListReference(list)
    }, [id, suggestions]);

    const isSuggestionOpen = () => {
        return (
            isFocused &&
            suggestions &&
            suggestions.length > 0 &&
            !suggestions.map((s) => s.text?.toLowerCase()).includes(value?.toLowerCase().trim())
        )
    }

    useOnClickOutside(outerReference, () => {
        setIsFocused(false)
    })

    const handleOnInputClick = (e) => {
        e.preventDefault()
        document.getElementById(id)?.focus()
        setIsFocused(!isFocused)
    }

    const handleOnKeyDown = (e) => {
        setIsFocused(true)
        //press down key button
        if (e.keyCode === 40 && isSuggestionOpen()) {
            e.preventDefault()
            setActiveIndex((activeIndex + 1) % suggestions.length)
            if (listReference?.children[activeIndex])
                listReference?.children[activeIndex].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
        }
        //press up key button
        if (e.keyCode === 38 && isSuggestionOpen()) {
            e.preventDefault()
            setActiveIndex(((activeIndex + suggestions.length - 1) % suggestions.length))
            if (listReference?.children[activeIndex])
                listReference?.children[activeIndex].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
        }
        //press enter
        if (e.keyCode === 13) {
            handleOnSuggestionClick(suggestions[activeIndex])
        }
    }

    const isNameEntered = () => {
        return suggestions.map((s) => s.text?.trim().toLowerCase()).includes(value?.trim()?.toLowerCase())
    }

    const areSuggestionsFound = () => {
        return suggestions.filter(s => s.text?.trim().toLowerCase().includes(value?.trim().toLowerCase())).length > 0
    }

    const handleOnInputClear = () => {
        if (!isNameEntered) return
        document.getElementById(id)?.focus()
        setIsFocused(true)
        clearField()
    }

    return (
        <span style={{position: "relative", width: '100%', display: 'inline-block'}} ref={outerReference}>
           <span className={"input-field-wrapper"} data-variant={variant} data-loading={loading}>
            {label !== undefined && <label className={"label"} htmlFor={id}>{label}</label>}
               <input
                   id={id}
                   className={'input-field'}
                   placeholder={placeholder}
                   data-variant={variant}
                   data-variant-type={variantType}
                   type={type}
                   name={type}
                   required={isRequired}
                   ref={reference}
                   value={value}
                   onKeyDown={handleOnKeyDown}
                   onClick={handleOnInputClick}
                   {...props}
               />
               {suggestions && !loading &&
                   <InputFieldRightIcon
                       nameEntered={isNameEntered()}
                       focused={isFocused}
                       b={areSuggestionsFound()}
                       length={value?.length}
                       onClick={isNameEntered() ? handleOnInputClear : handleOnInputClick}
                   />
               }

                { loading &&
                    <Oval
                        visible={loading}
                        height="20"
                        width="20"
                        color="#7B2AA1"
                        strokeWidth="6"
                        secondaryColor="#fff"
                        ariaLabel="oval-loading"
                        wrapperClass='spinner-oval'
                    />
                }

               {variant === 'submit' &&
                   <Button
                       variant={"secondary"}
                       icon={'fa-solid fa-paper-plane'}
                       onClick={onSubmit}
                       disabled={disableSubmit}
                       aria-label="Send prompt"
                   />
               }
            </span>
            {isSuggestionOpen() &&
                <SuggestionList id={id} dataPos={suggestionPos} map={suggestions.map((suggestion, index) =>
                    <React.Fragment key={`${suggestion.text}-${index}`}>
                        {suggestion.text !== 'loading' ?
                            <li
                                className={"auto-complete-item"}
                                data-active={activeIndex === index}
                                onClick={() => handleOnSuggestionClick(suggestion)}
                                onMouseEnter={() => setActiveIndex(index)}
                                tabIndex={0}
                            >
                                {suggestion.text}
                            </li>
                            :
                            <li className={"auto-complete-item"}>
                                {suggestion.text}
                            </li>
                        }
                    </React.Fragment>
                )}/>
            }
        </span>
    )
}

InputField.propTypes = {
    /**
     * Types of input field:
     */
    variant: PropTypes.oneOf(['default', 'submit', 'secondary']),
    /**
     * Input label
     */
    label: PropTypes.string,
    /**
     * Input placeholder
     */
    placeholder: PropTypes.string,
};

function SuggestionList(props) {
    return <ul id={"auto-complete-" + props.id} className={"auto-complete-list"} data-pos={props.dataPos}>
        {props.map}
    </ul>;
}

SuggestionList.propTypes = {
    id: PropTypes.any,
    dataPos: PropTypes.string,
    map: PropTypes.any
};

function InputFieldRightIcon(props) {
    return (
        <span className={"input-chevron"} onClick={props.onClick}>
            <i
                className={
                    props.nameEntered ?
                        "fa-solid fa-close"
                        : !props.focused ?
                            "fa-solid fa-chevron-down"
                            : props.b ?
                                "fa-solid fa-chevron-up"
                                : props.length > 0 ?
                                    "fa-solid fa-warning"
                                    :
                                    "fa-solid fa-chevron-up"
                }
            />
        </span>
    );
}

InputFieldRightIcon.propTypes = {
    nameEntered: PropTypes.any,
    focused: PropTypes.bool,
    b: PropTypes.bool,
    length: PropTypes.any,
    onClick: PropTypes.func
};