import React, {useContext, useEffect, useState} from 'react';
import "./Contract.scss";
import content from "./Contract.json";
import {InputField} from "../../components/input-field/InputField.jsx";
import {Popup} from "../../components/popup/Popup";
import {MultiStepProgressBar} from "../../components/loading_steps/MultiStepProgressBar";
import {fetchDirect, fetchStream} from "../../apis/getRequest";
import {UserContext} from "../../context/UserContext";
import {PageIntro} from "../../components/page-intro/PageIntro";
import {PlacementInfo} from "./components/PlacementInfo";
import {ContractForm} from "./components/ContractForm";
import {patchRequest, postRequest} from "../../apis/postRequest";
import {formatPlacements} from "./helpers/formatPlacements";
import {getSuggestions} from "./helpers/getSuggestions";
import { Spinner } from '../../components/spinner/Spinner.jsx';
import {Page} from "../../layouts/Page";
import {ContractBtns} from "./components/ContractBtns";
import {contractStream} from "./helpers/contractStream";
import {updateContractInfo} from "./helpers/updateContractInfo";

/**
 * Contract Page
 */
export const Contract = () => {
    const { getToken } = useContext(UserContext);

    const [inputValue, setInputValue] = useState('')
    const [placements, setPlacements] = useState([])
    const [suggestions, setSuggestions] = useState([])
    const [candidateInfo, setCandidateInfo] = useState({})
    const [fileContract, setFileContract] = useState(false)
    const [contractError, setContractError] = useState(false)
    const [contractURL, setContractURL] = useState('')

    const [isInputSelected, setIsInputSelected] = useState(false)
    const [isExtraInfoAdded, setIsExtraInfoAdded] = useState(false)
    const [contractExist, setContractExist] = useState('na')
    const [contractFilename, setContractFilename] = useState('')
    const [isContractBtnPressed, setIsContractBtnPressed] = useState(false)
    const [hasContractFormChanged, setHasContractFormChanged] = useState(false)
    const [isRegenerating, setIsRegenerating] = useState(false)

    const [steps, setSteps] = useState( [
        {refName: 'check_information', name: content.steps.checkInformation, status: "loading"},
        {refName: 'get_submission', name: content.steps.getSubmission, status: undefined},
        {refName: 'get_job', name: content.steps.getJob, status: undefined},
        {refName: 'fill_contract', name: content.steps.fillContract, status: undefined}
    ])

    const {
        onSourceMessage, onSourceError
    } = contractStream({
        setSteps: setSteps,
        setFileContract: setFileContract,
        setContractError: setContractError
    })

    //get submissions
    useEffect(() => {
        if (placements.length > 0) return
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/submissions/`, getToken())
            .then((response) => { setPlacements(formatPlacements(response)) })
            .catch((e) => {console.error(e)})
    }, [getToken, placements.length])

    //update submissions suggestions
    useEffect(() => {
        if (!placements || placements.length <= 0) return
        setSuggestions(getSuggestions(placements, inputValue))
    }, [placements, inputValue])

    //get submissions extra info
    useEffect(() => {
        if (!isInputSelected && !inputValue) return
        fetchDirect(
            `${process.env.REACT_APP_API_HOSTNAME}/recruitment/contract/${candidateInfo?.submission_id}/`,
            getToken()
        )
            .then((response) => {
                setIsExtraInfoAdded(true)

                if (!response) { setContractExist("doesNotExist"); return }
                setCandidateInfo(prevState => updateContractInfo({
                    prevState: prevState,
                    response: response,
                    setContractFilename: setContractFilename
                }))
                setContractExist('exist')
            })
            .catch((e) => {
                // setContractExist("doesNotExist");
                setIsExtraInfoAdded(true)
                console.error(e)
            })
    }, [getToken, inputValue, isInputSelected, candidateInfo?.submission_id])

    //create contract info if it does not exist
    useEffect(() => {
        if (!isInputSelected) return
        if (contractExist === 'doesNotExist') {
            postRequest({
                url: `${process.env.REACT_APP_API_HOSTNAME}/recruitment/contract/`,
                token: getToken(),
                data: candidateInfo
            }).catch((e) => {console.error(e)})
            setContractExist('exist')
        }
    }, [isExtraInfoAdded, contractExist, getToken, candidateInfo, isInputSelected])

    //update contract info if it exists and if the form changes
    useEffect(() => {
        if (!isInputSelected) return

        const updateContract = (newInfo) => {
            patchRequest({
                url: `${process.env.REACT_APP_API_HOSTNAME}/recruitment/contract/${newInfo?.submission_id}/`,
                token: getToken(),
                data: newInfo
            }).catch((e) => {console.error(e)})
        }

        if (contractExist === 'exist' && hasContractFormChanged) {
            updateContract(candidateInfo)
        }
    }, [hasContractFormChanged, isInputSelected, contractExist, getToken, candidateInfo, contractFilename])

    useEffect(() => {
        if (!candidateInfo?.submission_id) return
        setFileContract(true)
    }, [candidateInfo?.submission_id])

    //if file exists get url of contract
    useEffect(() => {
        if (fileContract) {
            fetchDirect(
                `${process.env.REACT_APP_API_HOSTNAME}/recruitment/contract/${candidateInfo?.submission_id}/download/`,
                getToken()
            ).then((response) => {
                setContractURL(response?.signed_url)
            }).catch((e) => {
                console.error(e)
            })
        }
    }, [fileContract, candidateInfo?.submission_id, getToken])

    //close page overlay if contract file exists and if there is an error
    useEffect(() => {
        if (!fileContract && !contractError) return
        const timer = setTimeout(() => {
            setIsContractBtnPressed(false)
        }, 1200);
        return () => clearTimeout(timer);
    }, [contractError, fileContract, getToken])

    const handleOnBlur = (e, id) => {
        setHasContractFormChanged(true)
        setCandidateInfo(prevState => {
            const contractInfo = {...prevState}
            contractInfo[id] = e.target.value
            return contractInfo
        })
    }

    const handleOnChange = (e, id) => {
        setCandidateInfo(prevState => {
            const contractInfo = {...prevState}
            contractInfo[id] = e.target.value
            return contractInfo
        })
    }

    const handleOnInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleOnSuggestionClick = (suggestion) => {
        if (!suggestion?.text) return
        window.scrollTo(0, 0)
        setContractExist('na')
        setHasContractFormChanged(false)
        setInputValue(suggestion.text)
        setIsInputSelected(true)
        setCandidateInfo(placements.filter((placement => placement.submission_id === suggestion.submission_id))[0])
    }

     const handleOnBtnClick = async () => {
        setSteps([
            {refName: 'check_information', name: content.steps.checkInformation, status: "loading"},
            {refName: 'get_submission', name: content.steps.getSubmission, status: undefined},
            {refName: 'get_job', name: content.steps.getJob, status: undefined},
            {refName: 'fill_contract', name: content.steps.fillContract, status: undefined}
        ])
        setContractError(false)
        setFileContract(false)
        setIsContractBtnPressed(true)

        const submissionID = candidateInfo?.submission_id || 0
        const url = `${process.env.REACT_APP_API_HOSTNAME}/recruitment/contract/generate/${submissionID}/`
        try {
            await fetchStream({
                url: url,
                token: getToken(),
                onmessage: onSourceMessage,
                onerror:onSourceError
            })
        } catch (e) {console.error(e)}
    }

    const onClearInput = () => {
        setInputValue('')
        setIsInputSelected(false)
    }

    const handleOnRegenerateClick = () => {
        setIsRegenerating(true)
        fetchDirect(
            `${process.env.REACT_APP_API_HOSTNAME}/recruitment/contract/${candidateInfo?.submission_id}/generate_tasks/`,
            getToken(),
            {regenerate: true}
        ).then((response) => {
            setCandidateInfo(prevState => {
                const contractInfo = {...prevState}
                contractInfo['job_tasks_summary'] = response['job_tasks_summary']
                return contractInfo
            })
            setIsRegenerating(false)
            setHasContractFormChanged(true)
        }).catch((e) => {
            setIsRegenerating(false)
            console.error(e)
        })
    }

    return (
        <Page>
            <Popup state={isContractBtnPressed ? "open" : "close"}>
                <MultiStepProgressBar steps={steps}/>
            </Popup>
            <PageIntro
                title={content.title}
                subTitle={content.subTitle}
                description={content.description}
                variant={"secondary"}
            />
            <Spinner visible={placements.length === 0}/>
            <div className={"contract-layout"}>
                {placements.length > 0 &&
                    <InputField
                        id={"main-input"}
                        label={content.labels.findSubmission}
                        value={inputValue}
                        variant={'secondary'}
                        variantType={'select'}
                        placeholder={content.placeholder.findSubmission}
                        suggestions={suggestions}
                        onChange={handleOnInputChange}
                        autoComplete={"off"}
                        handleOnSuggestionClick={handleOnSuggestionClick}
                        clearField={onClearInput}
                    />
                }
                {isInputSelected && <PlacementInfo candidateInfo={candidateInfo} />}
                <Spinner visible={isInputSelected && !isExtraInfoAdded}/>
                {isInputSelected && isExtraInfoAdded &&
                    <>
                        <ContractForm
                            candidateInfo={candidateInfo}
                            handleOnChange={handleOnChange}
                            handleOnBlur={handleOnBlur}
                            handleOnRegenerateClick={handleOnRegenerateClick}
                            isRegenerating={isRegenerating}
                        />
                        <ContractBtns
                            contractURL={contractURL}
                            contractFilename={contractFilename}
                            handleOnBtnClick={handleOnBtnClick}
                        />
                    </>
                }
            </div>
        </Page>
    )
}