import Card from "../../../components/card/Card";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {Spinner} from "../../../components/spinner/Spinner";
import React from "react";

export const JobInfoCard = ({index, userQuery, responses, newResponse, userQueriesLength}) => {
    return (
        <Card loading={!responses[index]}>
            <Card.Header>
                <Card.Icon icon={userQuery.icon}/>
                <Card.Title>{userQuery.title}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Card.Description reference={index === userQueriesLength - 1 ? newResponse : null}>
                    {responses[index] &&
                        <Markdown remarkPlugins={[remarkGfm]}>{responses[index]}</Markdown>
                    }
                </Card.Description>
            </Card.Body>
        </Card>
    )
}