import "./ButtonGroup.scss";

/**
 * ButtonGroup component for main UI
 */
export const ButtonGroup = ({id, children}) => {
    return (
       <div id={id} className={"button-group"}>
            {children}
        </div>
    )
}