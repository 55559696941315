import React, { useEffect, useContext } from 'react';
import '../../pages/login/Login.scss'
import { useNavigate, useLocation } from 'react-router-dom';
import { UserDispatchContext, UserContext } from '../../context/UserContext';
import { OAuth2Client } from '@badgateway/oauth2-client';
import { fetchDirect } from '../../apis/getRequest';
import { Background } from '../bg/Background';
import { Footer } from '../footer/Footer';
import { Spinner } from '../spinner/Spinner';


export const OAuth2RedirectHandler = () => {
  const navigate = useNavigate();
  const { storeToken, storeUser } = useContext(UserDispatchContext);
  const { getToken } = useContext(UserContext);

  var inProcess = false;

  const microsoftAuthToken = async () => {
    if (inProcess) return;
    inProcess = true;

    const client = new OAuth2Client({
      server: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/oauth2/v2.0`,
      clientId: `${process.env.REACT_APP_AZURE_APP_CLIENT_ID}`,
      discoveryEndpoint: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/v2.0/.well-known/openid-configuration`
    });

    const codeVerifier = localStorage.getItem('codeVerifier');
    localStorage.removeItem('codeVerifier');

    client.authorizationCode.getTokenFromCodeRedirect(document.location, {
      redirectUri: `${process.env.REACT_APP_HOSTNAME}/oauth2-redirect`,
      codeVerifier
    })
    .then((token) =>{
      return fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/auth/authenticate`, token.accessToken)
      .then((response)=>{
          storeToken(token);
          const preAuthPath = sessionStorage.getItem('preAuthPath') || '/';
          if(preAuthPath == '/login'){
            preAuthPath = '/'
          }
          sessionStorage.removeItem('preAuthPath');
          navigate(preAuthPath, { replace: true });
          storeUser(response.user)
        })
    })
    .catch((error) =>{
      console.error("Error during OAuth2 token retrieval: ", error);
      const errorParam = encodeURIComponent(error.message || 'Authentication failed');
      navigate(`/login?error=${errorParam}`, { replace: true });
    })

 };

  useEffect(() => {
    microsoftAuthToken();
  }, []);

  return( 
    <div className={"login-page"}>
            <Background />
            <FormWrapper>
                <LoginHeader />
                <form className={"login-form"}>
                  <Spinner/>
                </form>
            </FormWrapper>
            <Footer />

    </div>
  );
};

function LoginHeader() {
  return (
      <div className={"form-header"}>
          <div className={"bg-gradient"}></div>
          <div className={"logo-wrapper"}>
              <h1 className={"logo-title"}>{"Login..."}</h1>
              <h2 className={"logo-subtitle"}>{"Vérification des accès"}</h2>
              
          </div>
      </div>
  );
}

function FormWrapper({children}) {
  return (
      <div className={"form-wrapper"}>
          <div className={"form-container"}>
              {children}
          </div>
      </div>
  );
}
