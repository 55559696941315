import React, {useState} from "react";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";

export const LeftSection = () => {
    const [docs] = useState([
         { uri: require("../../../assets/HEARTY_CV.pdf") }
    ])
    return (
        <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            className="custom-doc-viewer-style"
            config={{pdfZoom: {defaultZoom: 1.1}}}
        />
    )
}