import React from "react";
import './Container.scss'

export const Container = ({children, icon, title}) => {
    return (
        <div className={"empty-container"}>
            {(icon || title) &&
                <div className={"empty-container-header"}>
                    <i className={icon}></i> {title}
                </div>
            }
            <div className={"empty-container-content"}>
                {children}
            </div>
        </div>
    )
}
