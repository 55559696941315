import React from "react";

export function Suggestions(props) {
    return (
        <>
            <div className={"suggestion-wrapper"} style={
                props.bubbleCount <= 0 ?
                    {opacity: 1, pointerEvents: "auto", visibility: 'visible'}
                    :
                    {opacity: 0, pointerEvents: "none", visibility: 'none'}}
            >
                {props.suggestions.map(props.callbackfn)}
            </div>
        </>
    );
}

export function ChatBubbles(props) {
    return (
        <div className={"chat-bubbles"}>
            {props.children}
        </div>
    );
}
