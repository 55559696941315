import {Grid, ThreeDots} from 'react-loader-spinner'
import React from "react";
export const Spinner = ({visible, variant= "grid"}) => {
    return (
        <>
            {variant === 'grid' ?
                <Grid
                    height="80"
                    width="80"
                    color="#896a94"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="spinner"
                    visible={visible}
                />
                :
                variant === 'line' &&
                <ThreeDots
                    visible={visible}
                    height="40"
                    width="40"
                    color="#896a94"
                    radius="5"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass="spinner"
                />
            }
        </>
    )
}