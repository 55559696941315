import React from 'react';
import "./MultiStepProgressBar.scss";

/**
 * Multistep progressbar component for main UI
 */
export const MultiStepProgressBar = ({label, steps}) => {
    return (
        <div>
            {label && <label>{label}</label>}
            <div className={"steps"}>
                {steps && steps.map((step, index) =>
                    <React.Fragment key={step.name + index}>
                        {index > 0 && <div data-state={step.status} className={"progress-indicator"}/>}
                        <div className={"step-wrapper"} data-state={step.status}>
                            <div className={"step"}>
                                <div className={"status-wrapper"}>
                                    {step.status === "done" ?
                                            <i className={"fa-solid fa-check"}></i>
                                        :
                                        step.status === "loading" ?
                                            <i className={"fa-solid fa-ellipsis"}></i>
                                        :
                                        step.status === "error" &&
                                            <i className={"fa-solid fa-xmark"}></i>
                                    }
                                </div>
                                <span className={'step-name'}>{step.name}</span>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}