export const chatStream = ({responses, setResponses, setIsResponseDone, newResponse}) => {
    const onSourceOpen = () => {
        setIsResponseDone(false)
        setResponses([...responses, " "])
    }

    const onSourceMessage = (event) => {
        const chunk = event.data.replaceAll('\\n', '\n').slice(1, -1)
        let currentResponseIndex = responses.length

        setResponses(prevResponses => {
            const currentContent = prevResponses[currentResponseIndex] || ""
            const updatedContent = currentContent + chunk
            const updatedResponses = [...prevResponses]
            updatedResponses[currentResponseIndex] = updatedContent
            return updatedResponses
        });
    }

    const onSourceError = (event)=> {
        throw event
    }

    const onSourceClose = () => {
        if (!newResponse?.current?.innerHTML) return
        setIsResponseDone(true)
    }

    return {onSourceOpen, onSourceMessage, onSourceError, onSourceClose}
}