   export const postRequest = async ({url, token, data})  => {
    return fetch( url , {
        method: "POST",
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        body : JSON.stringify(data)
    }).then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json()
    })
}

export const patchRequest = async ({url, token, data})  => {
    return fetch(url , {
        method: "PATCH",
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        body : JSON.stringify(data)
    }).then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json()
    })
}

export const putRequest = async ({url, token, data})  => {
    return fetch(url , {
        method: "PUT",
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json'
        },
        body : JSON.stringify(data)
    }).then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json()
    })
}

export const deleteRequest = async ({url, token})  => {
    return fetch(url , {
        method: "DELETE",
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json'
        }
    }).then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json()
    })
}