import React, {useContext, useEffect, useState} from "react";
import content from '../../Meeting.json'
import {InputField} from "../../../../components/input-field/InputField";
import {getJobSuggestions} from "../../helpers/getSuggestions";
import {fetchDirect} from "../../../../apis/getRequest";
import {UserContext} from "../../../../context/UserContext";
import {Spinner} from "../../../../components/spinner/Spinner";

export const JobDescription = ({selectedJob, setSelectedJob, setQuestions}) => {
    const { getToken } = useContext(UserContext)
    const [inputValue, setInputValue] = useState('')
    const [jobs, setJobs] = useState([])
    const [suggestions, setSuggestions] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(true)

    useEffect(() => {
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/`, getToken())
            .then((response) => {
                setJobs(response.data)
                setLoadingStatus(false)
            })
            .catch((e) => {console.error(e)})
    }, [getToken])

    //update jobTitle
    useEffect(() => {
        if (!selectedJob) return
        setInputValue(selectedJob?.title)
    }, [selectedJob])

    //update jobSelection
    useEffect(() => {
        if (!selectedJob) return
        //TODO: post request to update job title selection


    }, [selectedJob, setSelectedJob])

    //update suggestions
    useEffect(() => {
        if (!jobs || jobs.length <= 0) return
        setSuggestions(getJobSuggestions(jobs, inputValue))
    }, [jobs, inputValue])

    const handleOnInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const onClearInput = () => {
        setInputValue('')
        setSelectedJob(undefined)
        setQuestions([])
    }

    const handleOnSuggestionClick = (suggestion) => {
        if (!suggestion?.text) return
        setInputValue(suggestion.text)
        setSelectedJob(jobs.filter(job => job?.id === suggestion.id)[0])
    }

    return (
        <div className={"interview-section-inner-wrapper"}>
            <Spinner visible={loadingStatus} variant={"line"}/>
            {!loadingStatus &&
                <InputField
                    id={"job-input"}
                    label={content.jobDescription.inputLabel}
                    value={inputValue}
                    variant={'secondary'}
                    variantType={'select'}
                    placeholder={content.jobDescription.inputPlaceholder}
                    suggestions={suggestions}
                    onChange={handleOnInputChange}
                    autoComplete={"off"}
                    handleOnSuggestionClick={handleOnSuggestionClick}
                    clearField={onClearInput}
                />
            }
            {selectedJob &&
                <div className={"interview-section-description"}>
                    <div dangerouslySetInnerHTML={{ __html: selectedJob?.description }} />
                </div>
            }
        </div>
    )
}