export const updateContractInfo = ({prevState, response, setContractFilename}) => {
    const contractInfo = {...prevState}
    Object.keys(response).forEach((field) => {
        if (response[field] && response[field].length > 0)
            contractInfo[field] = response[field]
    })

    if (contractInfo?.filename && contractInfo?.filename.length > 0) {
        setContractFilename(contractInfo?.filename)
    }
    return contractInfo
}