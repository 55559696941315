export const contractStream = ({setSteps, setFileContract, setContractError}) => {
    const onSourceMessage = (event) => {
        const data = JSON.parse(event.data)
        setSteps(prevSteps => {
            const updatedSteps = [...prevSteps]
            updatedSteps.forEach((step, index) => {
                updatedSteps[index] = {...step, status: updateStatus(step, index, updatedSteps, data)}
            })
            return updatedSteps
        })

        if (data['fill_contract']) setFileContract(true)

        const updateStatus = (step, index, updatedSteps, data) => {
            if (data[step.refName]) return "done"
            else if (data.error) return "error"
            else if (updatedSteps[index - 1]?.status === "done") return "loading"
            else return undefined
        }
    }

    const onSourceError = (event) => {
        setContractError(true)
        throw event
    }

    return {onSourceMessage, onSourceError}
}