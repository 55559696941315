import { fetchEventSource } from "@microsoft/fetch-event-source";

export const fetchStream = async ({url, token, onopen, onmessage, onclose, onerror, params = {}}) => {

  await fetchEventSource(url += Object.keys(params).length !== 0 ? '?' + (new URLSearchParams(params)).toString() : "", {
      method: "GET",
      openWhenHidden: true,
      headers: {
        Accept: "text/event-stream",
        Authorization: `Bearer ${token}`
      },
      onopen: onopen,
      onmessage: onmessage,
      onclose: onclose,
      onerror: onerror
  });
};

export const fetchDirect = async (url, token, params = {})  => {
  const targetUrl =  url += Object.keys(params).length !== 0 ? '?' + (new URLSearchParams(params)).toString() : ""
  return fetch(targetUrl, {
    method: "GET",
    headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
    },
  }).then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1)
        return response.json()
      else
        return response.text()
  })
}