import React, {useContext, useEffect, useState} from "react";
import './Meeting.scss'
import {ResizablePane} from "../../components/resizable-pane/ResizablePane";
import {Page} from "../../layouts/Page";
import {useLocation, useParams} from "react-router-dom";
import {fetchDirect, fetchStream} from "../../apis/getRequest";
import {UserContext} from "../../context/UserContext";
import {RightSection} from "./right-section/RightSection";
import {LeftSection} from "./left-section/LeftSection";
import {interviewStream} from "./helpers/interviewStream";
import {patchRequest} from "../../apis/postRequest";

export const Meeting = () => {
    const { getToken } = useContext(UserContext)
    const location = useLocation()
    const {meetingId } = useParams()
    const [interview, setInterview] = useState()
    const [transcriptActive, setTranscriptActive] = useState(false)
    const [transcriptSummary, setTranscriptSummary] = useState([])
    const [isResponseDone, setIsResponseDone] = useState(false)

    const {
        onSourceOpen,
        onSourceMessage,
        onSourceError,
        onSourceClose
    } = interviewStream({
        response: transcriptSummary,
        setResponse: setTranscriptSummary,
        setIsResponseDone: setIsResponseDone
    })

    //save generated notes
    useEffect(() => {
        if (!isResponseDone) return

        patchRequest({
            url: `${process.env.REACT_APP_API_HOSTNAME}/recruitment/interviews/${meetingId}`,
            token: getToken(),
            data: {"generated_notes": transcriptSummary[0]}
        }).catch((e) => {console.error(e)})

    }, [getToken, isResponseDone, meetingId, transcriptSummary]);

    //get interview
    useEffect(() => {
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/interviews/${meetingId}/`, getToken())
            .then((response) => {
                setInterview(response)
                if (!response?.generated_notes) return
                const tempNotes = []
                tempNotes[0] = response?.generated_notes
                setTranscriptSummary(tempNotes)
            })
            .catch((e) => {
                console.error(e)
            })
    }, [getToken, meetingId])


    const showTranscriptPage = async () => {
        setTranscriptActive(true)
        if (transcriptSummary.length > 0) return
        try {
            await fetchStream({
                url: `${process.env.REACT_APP_API_HOSTNAME}/recruitment/interviews/${meetingId}/generate_notes/`,
                token: getToken(),
                onmessage: onSourceMessage,
                onopen: onSourceOpen,
                onclose: onSourceClose,
                onSourceError: onSourceError,
            })
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Page id={"interview-page"} splitScreen={true} data-is-animated={!transcriptActive}>
            <ResizablePane initialSize={'50%'} id={"cv-panel"} minSize={'30%'}>
                <LeftSection
                    meetingId={meetingId}
                    meetingSubject={location.state?.meetingSubject}
                    transcriptActive={transcriptActive}
                    transcriptSummary={transcriptSummary}
                />
            </ResizablePane>
            <ResizablePane grow={true} id={"interview-panel-info"} minSize={'30%'}>
                <RightSection
                    interview={interview}
                    meetingId={meetingId}
                    transcriptActive={transcriptActive}
                    showTranscriptPage={showTranscriptPage}
                />
            </ResizablePane>
        </Page>
    )
}
