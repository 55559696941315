import React, {useContext, useState, useEffect} from 'react';
import {UserContext} from "../../context/UserContext";
import {fetchDirect} from "../../apis/getRequest";
import {prepareJobs} from "../../pages/jobs/helpers/JobsPrepare";
import Card from "../card/Card";
import {Table} from "../table/Table";
import {Tag} from "../tag/Tag";
import { Button } from '../button/Button';

export const RecruiterDashboard = ({user}) => {
    const { getToken } = useContext(UserContext)
    const [jobs, setJobs] = useState([])
    const [candidates, setCandidates] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(true)

    useEffect(() => {
        setLoadingStatus(true)
        const params = {
            "job_types": "1,2,3"
        }
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/`, getToken(), params)
        .then((response) => {
            const data = response.data
            setJobs(prepareJobs(data))
            setLoadingStatus(false)
        })
        .catch((e) => {console.error(e)})
    }, []);

    function getBhID(user){
        const bhIntegration = user?.integrations?.filter(int => int.name == 'bullhorn')
        if(bhIntegration && bhIntegration.length > 0)
        return bhIntegration[0].integration_id
   }

    useEffect(() => {
        const toSave = []
        const bdId = getBhID(user)
        jobs.map((job) => { job.submissions.data.map((submission) => {
            const ownerId = submission.candidate.owner.id
            if(ownerId != bdId)
                return
            if(submission?.category !== "refused"){
                toSave.push({
                    "id": submission?.candidate?.id,
                    "name": submission?.candidate?.firstName + " " + submission?.candidate?.lastName,
                    "jobTitle": job?.title,
                    "jobClient": job?.clientCorporation?.name,
                    "status": submission?.status,
                    "category": submission?.category
                })
            }})
        })
        setCandidates(toSave)
    }, [jobs])

    const getInprocessCount = () => candidates.filter(c => ['submitted','inprocess'].includes(c.category)).length
    const getAwaitingCount = () => candidates.filter(c => c.category === 'submitted').length
    const getInterviewCount = () => candidates.filter(c => c.status === 'Interview Scheduled').length
    
    return (
        <>
            <div className="card-dashboard">
                <Card loading={loadingStatus}>
                    <Card.Body>
                        <h1>{getInprocessCount()}</h1>
                        <h4>{"Candidats en processus"}</h4>
                    </Card.Body>
                </Card>
                <Card loading={loadingStatus}>
                    <Card.Body>
                        <h1>{getAwaitingCount() || 0}</h1>
                        <h4>{"Dépôts en attente"}</h4>
                    </Card.Body>
                </Card>
                <Card loading={loadingStatus}>
                    <Card.Body>
                        <h1>{getInterviewCount() || 0}</h1>
                        <h4>{"Candidats en entrevue"}</h4>
                    </Card.Body>
                </Card>
            </div>

            <h2>Candidats en processus</h2>

            <Table state={loadingStatus ? "loading" : "default"}>
                <Table.Head numOfColumns={6}>
                    <Table.Header></Table.Header>
                    <Table.Header>Candidat</Table.Header>
                    <Table.Header>Client</Table.Header>
                    <Table.Header>Titre du Poste</Table.Header>
                    <Table.Header>Statut</Table.Header>
                    <Table.Header></Table.Header>
                </Table.Head>
                <Table.Body>
                    {candidates?.map((candidate, index) => (
                        <Table.Row index={index} key={"table-row-" + index} id={candidate?.id}>
                            <Table.Cell>{candidate?.id}</Table.Cell>
                            <Table.Cell>{candidate?.name}</Table.Cell>
                            <Table.Cell>{candidate?.jobClient}</Table.Cell>
                            <Table.Cell>{candidate?.jobTitle}</Table.Cell>
                            <Table.Cell>
                                <Tag label={candidate?.status}></Tag>
                            </Table.Cell>
                            <Table.Cell>
                                <a href={`https://cls33.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${candidate?.id}`} target='_blank'>
                                    <Button size={"small"} variant="primary" icon={"fa-solid fa-arrow-up-right-from-square"}/>
                                </a>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer>
                </Table.Footer>
            </Table>
        </>
    )
}