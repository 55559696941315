import React, {useState} from 'react';
import "./Table.scss";
import {ButtonGroup} from "../button-group/ButtonGroup";
import {Button} from "../button/Button";
import {Tag} from "../tag/Tag";

/**
 * Table component for main UI
 */
export const Table = ({children, maxHeight, state}) => {
    Table.state = state
    return (
        <div className={"table-wrapper"} style={{maxHeight: maxHeight}}>
            <table className={"table"} data-state={state}>
                {children}
            </table>
        </div>
    );
}

Table.Head = function TableHead ({children, numOfColumns}) {
    Table.numOfColumns = numOfColumns
    return (
        <thead className={"table-head"}>
            <tr>
                {children}
            </tr>
        </thead>
    );
}

Table.Header = function TableHeader ({children, colWidth, align = 'left', condensedHorizontally= false, leftSticky = false}) {
    return (
        <td
            className={"table-header"}
            width={colWidth}
            data-left-sticky={leftSticky}
            data-condensed-horizontal={condensedHorizontally}
            style={{textAlign: align}}
        >
            {children}
        </td>
    );
}

Table.Body = function TableBody ({children}) {
    return (
        <tbody className={"table-body"}>
            {children}
            {Table.state === "loading" &&
                [...Array(8)].map((x, i) =>
                    <Table.Row key={"table-row-loading" + i}>
                        {[...Array(Table?.numOfColumns || 8)].map((x, i) =>
                            <Table.Cell key={"loading-cell-" + i}><span className={"loading-spacer"}></span></Table.Cell>
                        )}
                    </Table.Row>
                )
            }
        </tbody>
    );
}

Table.Row =  function TableRow ({children, index, nestedRow, onClick}) {
    Table.Row.clicked = false
    const [isOpen, setIsOpen] = useState(false)

    const handleOnClick = (e) => {
        if (Table.Row.clicked) {
            setIsOpen(!isOpen)
        } else {
            Table.Row.clicked = false
            onClick(e)
        }
    }

    return (
        <>
            <tr
                className={"table-row"}
                data-state={index % 2 === 0 ? "default": "dark"}
                data-variant={onClick ? "clickable" : ""}
                onClick={handleOnClick}
            >
                {children}
            </tr>
            {isOpen &&
                <tr className={"table-row-nested"}>
                    <td colSpan={Table.numOfColumns}>
                        <div className={"wrapper"}>
                            {nestedRow}
                        </div>
                    </td>
                </tr>
            }
        </>
    );
}

Table.Cell =  function TableCell ({children, align = 'left', condensedHorizontally= false, leftSticky = false, onClick, padding = true, clickable = true}) {
    const handleOnClick = (e) => {
        if (onClick) onClick(e)
        if (!clickable && (e.target.tagName === "TD" || e.target.tagName === "TR" || e.target.tagName === "DIV")) {
            e.stopPropagation()
        }
    }

    return (
        <td
            className={"table-cell"}
            data-left-sticky={leftSticky}
            data-condensed-horizontal={condensedHorizontally}
            data-padding={padding}
            onClick={handleOnClick}
        >
            <div className={"table-inner-cell"} style={{justifyContent: align}}>
                {children}
            </div>
        </td>
    );
}

Table.Chevron = function TableChevron () {
    const [isOpen, setIsOpen] = useState(false)
    const handleOnClick = () => {
        setIsOpen(!isOpen)
        Table.Row.clicked = true
    }

    return (
        <button className={"table-chevron"} data-state={isOpen? "open" : "closed"} onClick={handleOnClick}>
            <i className="fa-regular fa-chevron-right"></i>
        </button>
    );
}

Table.Footer = function TableFooter ({children}) {
    return (
        <tfoot className={"table-footer"}>
            {children}
        </tfoot>
    );
}

Table.Pagination = function Pagination ({totalPageNum, prevClick, nextClick}) {
    const [currentPage, setCurrentPage] = useState(1)

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
            if (prevClick) prevClick(currentPage)
        }
    }

    const handleNextClick = () => {
        if (currentPage < totalPageNum) {
            setCurrentPage(currentPage + 1)
            if (nextClick) nextClick(currentPage)
        }
    }

    return (
        <tr>
            <td className={"pagination"} colSpan={Table.numOfColumns}>
                <ButtonGroup>
                    <Button
                        icon={"fa-regular fa-arrow-left-long"}
                        onClick={handlePrevClick}
                        disabled={currentPage === 1}
                    />
                    <Tag label={`Page ${currentPage} / ${totalPageNum}`}/>
                    <Button
                        icon={"fa-regular fa-arrow-right-long"}
                        onClick={handleNextClick}
                        disabled={currentPage === totalPageNum}
                    />
                </ButtonGroup>
            </td>
        </tr>
    )
}