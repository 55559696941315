import React from "react";
import './CollaspableContainer.scss'

export const CollapsableContainer = ({children, id, isOpen = true}) => {
    return (
        <details id={id} className={"collapsable-container"} open={isOpen}>
            {children}
        </details>
    )
}

CollapsableContainer.Header = function ContainerHeader ({ children, icon, title, handleOnToggle }) {
    return (
        <summary
            className={"collapsable-container-header"}
            onClick={handleOnToggle}
        >
            <span className={"container-header-inner-wrapper"}>
                <span>
                    <i className={icon}></i> {title}
                </span>
                <span>
                    {children}
                </span>
            </span>
        </summary>
    )
}

CollapsableContainer.Body = function ContainerBody ({ children, numOfSectionsCollapsed = 0 }) {
    return (
        <div className={"collapsable-container-content"} data-open={numOfSectionsCollapsed}>
            {children}
        </div>
    )
}





