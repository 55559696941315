import React, {useContext, useEffect, useState} from "react";
import content from '../../Meeting.json'
import {CollapsableContainer} from "../../../../components/collapsable-container/CollapsableContainer";
import {Textarea} from "../../../../components/textarea/Textarea";
import {Button} from "../../../../components/button/Button";
import {fetchDirect} from "../../../../apis/getRequest";
import {UserContext} from "../../../../context/UserContext";

export const ValidateInterviewSection = ({notes, handleOnNoteChange, handleOnNoteBlur}) => {
    const [collapsableMenuState, setCollapsableMenuState] = useState([true])
    const [isLoading, setIsLoading] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)

    useEffect(() => {
        if (!isLoading) return
        const intervalId = setInterval(() => {
            setIsCompleted(true)
            setIsLoading(false);
        },  Math.floor(Math.random() * (1600 - 800 + 1) + 800));
        return () => clearInterval(intervalId);
    }, [isLoading]);

    const handleOnToggle = async (e, index) => {
        e.preventDefault()
        setCollapsableMenuState((prevState) => {
            const currState = [...prevState]
            currState[index] = !currState[index]
            return currState
        })
    }

    const handleOnButtonClick = () => {
        setIsLoading(true)
    }

    return (
        <div className={"panel-layout-container"}>
            <CollapsableContainer isOpen={collapsableMenuState[1]}>
                <CollapsableContainer.Header
                    icon={content.notes.icon}
                    title={content.notes.sectionName}
                    handleOnToggle={(e) => handleOnToggle(e, 1)}
                />
                <CollapsableContainer.Body>
                    <Textarea
                        placeholder={content.notes.inputPlaceholder}
                        variant={"secondary"}
                        value={notes}
                        onChange={handleOnNoteChange}
                        onBlur={handleOnNoteBlur}
                        style={{height: 'calc(100vh - 56px - 160px)'}}
                    />
                </CollapsableContainer.Body>
            </CollapsableContainer>
            <div className={"interview-action-button"}>
                <Button
                    variant={"primary"}
                    state={isLoading ? "loading" : "default"}
                    icon={isLoading ? "fa-duotone fa-spinner-scale fa-spin" : isCompleted ? "fa-duotone fa-check" : ""}
                    label={content.buttonLabels.secondConfirmationLabel}
                    onClick={handleOnButtonClick}
                />
            </div>
        </div>
    )
}