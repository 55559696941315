import React, {useContext, useState, useEffect} from 'react';
import {UserContext} from "../../context/UserContext";
import {fetchDirect} from "../../apis/getRequest";
import {prepareJobs} from "../../pages/jobs/helpers/JobsPrepare";
import Card from "../card/Card";
import {Table} from "../table/Table";
import {JobTable} from "../../pages/jobs/components/JobTable";
import { Button } from '../button/Button';
import {useNavigate} from "react-router-dom";
import { Tag } from '../tag/Tag';

export const SalesDashboard = ({user}) => {
    const { getToken } = useContext(UserContext);
    const [jobs, setJobs] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [candidates, setCandidates] = useState({});
    const navigate = useNavigate()


    async function loadJob(){
        const bhIntegration = user?.integrations?.filter(int => int.name === 'bullhorn')
        if(bhIntegration && bhIntegration.length > 0){
            const bhId = bhIntegration[0].integration_id
            const params = {    
                "job_types": "1,2,3",
                "owner_id" : bhId
            }
            return fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/`, getToken(), params)
            .then((response) => {
                setJobs(prepareJobs(response.data));
                setLoadingStatus(false);
                return response.data
            });
        }
   }

   function getBhID(user){
        const bhIntegration = user?.integrations?.filter(int => int.name === 'bullhorn')
        if(bhIntegration && bhIntegration.length > 0)
        return bhIntegration[0].integration_id
   }

    function getAwaitingCount(jobs){
        return jobs?.map((job) =>{return job.submissionsCount.submitted || 0}).reduce((a,b) => a+b, 0)
    }

    function getInprocessCount(jobs){
        return jobs?.map((job) =>{return job.submissionsCount.inprocess || 0}).reduce((a,b) => a+b, 0)
    }

    useEffect(() => {
        loadJob()
    }, []);

    useEffect(() => {
        const toSave = {}
        jobs?.forEach((job) => { job?.submissions.data.forEach((submission) => {
            if(submission?.category === "submitted") {
                if(!toSave[job?.id])
                    toSave[job?.id] = {
                        "id": job?.id,
                        "title": job?.title,
                        "client": job?.clientCorporation.name,
                        candidates: []    
                    }
                toSave[job?.id].candidates.push({
                    "id": submission?.candidate.id,
                    "name": submission?.candidate.firstName + " " + submission.candidate.lastName,
                    "status": submission?.status,
                    "salary" : submission?.payRate > 0 ? submission.payRate : submission.salary,
                    "category": submission?.category
                })}
            })
        })
        setCandidates(toSave)

    }, [jobs]);

    const handleRowSelect = (jobId) => {
        navigate('/jobs/' + jobId);
    }

    const handleRowSelectCandidate = (candidateId) => {
        navigate('/candidates/' + candidateId);
    }

    return (
        <>
            <div className="card-dashboard">
                <Card loading={loadingStatus}>
                    <Card.Body>
                        <h1>{jobs?.length}</h1>
                        <h4>{"Postes ouverts"}</h4>
                    </Card.Body>
                </Card>
                <Card loading={loadingStatus}>
                    <Card.Body>
                        <h1>{getAwaitingCount(jobs)}</h1>
                        <h4>{"Qualifications en attente"}</h4>
                    </Card.Body>
                </Card>
                <Card loading={loadingStatus}>
                    <Card.Body>
                        <h1>{getInprocessCount(jobs)}</h1>
                        <h4>{"Candidat en processus"}</h4>
                    </Card.Body>
                </Card>
            </div>

            <h2>Postes ouverts</h2>
            <JobTable userFilter={getBhID(user)} handleRowSelect={handleRowSelect} />

            <br/><br/>

            <h2>Candidats à évaluer</h2>

            {candidates &&
                Object.getOwnPropertyNames(candidates).map((jobId) => {
                    const jobCandidates = candidates[jobId]
                    return (
                        <>
                            <h3>{`${jobCandidates.title} - ${jobCandidates.client}`}</h3>
                            <Table state={loadingStatus ? "loading" : "default"}>
                                <Table.Head numOfColumns={5}>
                                    <Table.Header>Candidat</Table.Header>
                                    <Table.Header>Taux</Table.Header>
                                    <Table.Header>Status</Table.Header>
                                    <Table.Header></Table.Header>
                                </Table.Head>
                                <Table.Body>
                                {
                                    jobCandidates.candidates.map((candidate, index) =>{
                                       return ( 
                                        <Table.Row index={index} key={"table-row-" + index} id={candidate?.id}>
                                            <Table.Cell>{candidate?.name}</Table.Cell>
                                            <Table.Cell>{candidate?.salary} $</Table.Cell>
                                            <Table.Cell><Tag label={candidate?.status} /></Table.Cell>
                                            <Table.Cell>
                                                <a href={`https://cls33.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${candidate?.id}`} target='_blank' rel="noreferrer">
                                                    <Button size={"small"} variant="primary" icon={"fa-solid fa-arrow-up-right-from-square"}/>
                                                </a>
                                            </Table.Cell>
                                        </Table.Row>)
                                    })  
                                }
                                </Table.Body>
                                <Table.Footer/>
                            </Table>
                        </>
                    )
                })
            }

        </>
    )
}