import React, {useContext, useEffect, useState} from "react";
import './Meetings.scss'
import {PageIntro} from "../../components/page-intro/PageIntro";
import content from "./Meetings.json";
import {Table} from "../../components/table/Table";
import {UserContext} from "../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {fetchDirect} from "../../apis/getRequest";
import {Page} from "../../layouts/Page";
import {Tag} from "../../components/tag/Tag";
import {ButtonGroup} from "../../components/button-group/ButtonGroup";
import {Button} from "../../components/button/Button";

export const Meetings = () => {
    const { getToken } = useContext(UserContext)
    const [meetings, setMeetings] = useState([])
    const [meetingsStatus, setMeetingsStatus] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/interviews/`, getToken())
            .then((response) => {
                const statuses = []
                const currentDate = new Date()
                const sortedMeetings = response.sort((a, b) => new Date(b?.start?.dateTime + 'Z') - new Date(a?.start?.dateTime + 'Z'))

                sortedMeetings.forEach((meeting) => {
                    const startDate = new Date(meeting?.start?.dateTime + 'Z')
                    const endDate = new Date(meeting?.end?.dateTime + 'Z')

                    if (currentDate > startDate && currentDate < endDate)
                        statuses.push('En cours')
                    else if (startDate > currentDate)
                        statuses.push('À venir')
                    else if (endDate < currentDate)
                        statuses.push('Passé')
                })
                setMeetingsStatus(statuses)
                setMeetings(sortedMeetings)
                setLoadingStatus(false)
            })
            .catch((e) => {console.error(e)})

    }, [getToken])

    const handleRowSelect = (meeting) => {
        const meetingId  = meeting?.id
        const meetingSubject = `${meeting?.subject} ${formatDate(meeting?.start?.dateTime, meeting?.end?.dateTime)}`
        navigate(`/meetings/${meetingId}`, {state: {meetingSubject: meetingSubject}})
    }

    const formatDate = (startDate, endDate) => {
        const startEvent =  new Date(startDate + 'Z')
        const endEvent =  new Date(endDate + 'Z')

        const datePart1 = startEvent.toLocaleString('fr-FR',
            { month: 'long', day: 'numeric', year: 'numeric' }
        )
        const datePart2 = startEvent.toLocaleTimeString('fr-FR', {timeStyle:'short'})
        const datePart3 = endEvent.toLocaleTimeString('fr-FR', {timeStyle:'short'})

        return datePart1 + " " + datePart2 + " – " + datePart3
    }

    return (
        <Page id={"meetings-page"}>
            <PageIntro
                title={content.title}
                subTitle={content.subTitle}
                description={content.description}
                variant={"secondary"}
            />
            <Table state={loadingStatus ? "loading" : "default"}>
                <Table.Head numOfColumns={3}>
                    <Table.Header>Status</Table.Header>
                    <Table.Header>Sujet</Table.Header>
                    <Table.Header>Date</Table.Header>
                </Table.Head>
                <Table.Body>
                    {meetings?.map((meeting, index) => (
                        <Table.Row key={"table-row-" + index} index={index} onClick={() => handleRowSelect(meeting)}>
                            <Table.Cell>
                                <Tag
                                    label={meetingsStatus[index]}
                                    variant={
                                        meetingsStatus[index] === 'En cours' ?
                                            "primary" : meetingsStatus[index] === 'Passé' ?
                                               "tertiary" : ""
                                    }
                                />
                            </Table.Cell>
                            <Table.Cell>{meeting?.subject}</Table.Cell>
                            <Table.Cell>{formatDate(meeting?.start?.dateTime, meeting?.end?.dateTime)}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer></Table.Footer>
            </Table>
        </Page>
    )
}