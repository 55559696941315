import React, {useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import "./Header.scss";
import content from "./Header.json";
import {UserContext, UserDispatchContext} from "../../context/UserContext";
import {Button} from "../button/Button";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {Avatar} from "../avatar/Avatar";
import {Dropdown} from "../dropdown/Dropdown";

/**
 * Header component for main UI
 */
export const Header = () => {
    const { user, setUserInfo } = useContext(UserContext);
    const { getUser, setUser, deleteUser, deleteToken } = useContext(UserDispatchContext);
    const ref = useRef()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    useOnClickOutside(ref, () => {
        setIsMenuOpen(false)
    })

    const handleOnMenuClick = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const handleOnLogoutClick = () => {
        setUser(null)
        deleteUser()
        deleteToken()
    }

    var processUser = false

    useEffect(() => {
        if(!user && !processUser){
            processUser = true;
            setUserInfo()
        }
    }, [setUserInfo, user]);

    const checkUserGroups = (requiredGroups) => {
        const user = getUser()
        if(!user) return false;
        const userGroups = user.groups.map(group => group.name);
        return requiredGroups.every(group => userGroups.includes(group));
    }

    return (
        <header>
            <div className={"header-bg"}></div>
            <div className={"header-wrapper"}>
                <nav className={"main-nav"}>
                    <a className={"link"} href={'/'}>AI Agent</a>
                    <span className={"header-bar"}/>
                    {content.links.map((link, index) => {
                        if(checkUserGroups(link.groups))
                            return (
                                <a
                                    className={"link"}
                                    key={`link-key${index}`}
                                    href={link.url}
                                    data-active={location.pathname === link.url}
                                >
                                    {link.name}
                                </a>
                            )
                    })}
                </nav>
                <nav className={"secondary-nav"}>
                    {content.secondaryLinks.map((link, index) => {
                        if(checkUserGroups(link.groups))
                            return (
                                <div
                                    key={"header-link-secondary" + index}
                                    className={"icon-link"}
                                    onClick={() => navigate(link.url, { replace: true })}
                                >
                                    <i className={link.icon}></i>
                                </div>
                            )
                    })}
                    {user &&
                        <Dropdown alignment={"right"}>
                            <Dropdown.Button>
                                <Avatar
                                    initial={user?.name?.charAt(0)}
                                    imgSrc={user?.profile_pic && `data:image/jpeg;base64,${user?.profile_pic}`}
                                    onClick={handleOnMenuClick}
                                    tabIndex={0}
                                />
                            </Dropdown.Button>
                            <Dropdown.Menu>
                                <Dropdown.MenuItem>
                                    <div className={"menu"}>
                                        <span className={"menu-name"}>{user?.name}</span>
                                        <span className={"menu-email"}>{user?.email}</span>
                                        <Button
                                            variant={"primary"}
                                            label={"Se déconnecter"}
                                            size={"small"}
                                            onClick={handleOnLogoutClick}
                                        />
                                    </div>
                                </Dropdown.MenuItem>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </nav>
            </div>
        </header>
    );
}
