import React, {useContext, useRef, useState, useEffect} from 'react';
import { postRequest, putRequest, deleteRequest } from "../../../apis/postRequest.js";
import {fetchDirect} from "../../../apis/getRequest";
import {UserContext} from "../../../context/UserContext";
import {Avatar, AvatarGroup, ClosableAvatar} from "../../../components/avatar/Avatar.jsx";
import {Table} from "../../../components/table/Table.jsx";
import {Tag} from "../../../components/tag/Tag";
import { ButtonGroup } from '../../../components/button-group/ButtonGroup.jsx';
import { Button } from '../../../components/button/Button.jsx';
import {prepareCandidates} from "../helpers/CandidatesPrepare";
import PropTypes from "prop-types";
import { ModalList } from '../../../components/modal/Modal.jsx';

export const CandidatesTable = ({handleRowSelect, displayFilters, userFilter}) => {
    const { getToken } = useContext(UserContext);
    const [candidates, setCandidates] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [usersPics, setUsersPics] = useState({})
    const [recruiterList, setRecruiterList] = useState([])
    const [direction, setDirection] = useState(['next'])

    // const [nextPage, setNextPage] = useState(1)
    // const [currentPage, setCurrentPage] = useState(null)
    // const [previousPage, setPreviousPage] = useState(null)
    const [newPage, setNewPage] = useState(1)

    const [candidateCount, setCandidateCount] = useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const loadCandidates = async () => {

        //🤔- Added complexity?
        // let newPage = null
        // if (direction[0] === "prev" && previousPage) {
        //     newPage = previousPage.toString()
        // } else if (direction[0] === "next" && nextPage) {
        //     newPage = nextPage.toString()
        // } else {
        //     return candidates
        // }

        const params = {
            "search_query": "*",
            "page": newPage,
            "page_size": rowsPerPage.toString()
        }

        if(userFilter)
            params["owner_id"] = userFilter

        const response = await fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/candidates/`, getToken(), params);

        if (response) {
            //🤔- Is this needed if the next page is +1 is this better handled in the front or back?
            // setNextPage(response.next)
            // setPreviousPage(response.previous)
            //setCurrentPage(newPage)

            setCandidateCount(response.count)
            return response.results
        } else {
            return []
        }
    }

    async function refreshCandidate(loading= true){
        if(refreshCandidate) {
            setCandidates([])
            setLoadingStatus(loading)
        }
        const candidates = await loadCandidates();
        setCandidates(candidates);
        setLoadingStatus(false);
    }

    useEffect(() => {
        refreshCandidate()
    }, [direction, setNewPage]);


    useEffect(() =>{
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/users/pictures`, getToken())
        .then((pictures) => {
            const usersPics = {}
            const recruiters = []
            pictures?.forEach(p => {
                usersPics[p.integration_id] = p.profile_pic
                const avatar = <Avatar initial={p.name[0]} imgSrc={p.profile_pic ? `data:image/jpeg;base64,${p.profile_pic}` : null}/>
                //recruiters.push({id: p.integration_id, text:p.name, content:avatar})
                recruiters.push({id: p.integration_id, content:avatar})
            })
            setUsersPics(usersPics)
            setRecruiterList(recruiters.reverse())
        });
    },[getToken])

    // function getNextPage(types){
    //     setDirection(types)
    // }

    const handleOnNextPage = (nextPage) => {
        setNewPage(nextPage)
    }

     return (
        <>
            <Table state={loadingStatus ? "loading" : "default"}>
                <Table.Head numOfColumns={5}>
                    <Table.Header>Id</Table.Header>
                    <Table.Header>Name</Table.Header>
                    <Table.Header>Ownership</Table.Header>
                    <Table.Header align={"center"}>Hot List</Table.Header>
                    <Table.Header>Status</Table.Header>
                </Table.Head>
                <Table.Body>
                    {candidates?.map((candidate, index) => (
                        <Table.Row key={"table-row-" + index} onClick={(e) => handleRowSelect(candidate?.id, e)} id={candidate?.id}>
                            <Table.Cell>{candidate?.id}</Table.Cell>
                            <Table.Cell>{candidate?.name}</Table.Cell>
                            <Table.Cell>{candidate?.ownership}</Table.Cell>
                            <Table.Cell align={"center"} condensedHorizontally={false}>
                                <Tag label={candidate?.hot_list ? 'T' : 'F'} variant="primary"/>
                            </Table.Cell>
                            <Table.Cell>{candidate?.status}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer>
                    <Table.Pagination
                        totalPageNum={Math.ceil(candidateCount / rowsPerPage)}
                        prevClick={(currPage) => handleOnNextPage(currPage - 1)}
                        nextClick={(currPage) => handleOnNextPage(currPage + 1)}
                    />
                </Table.Footer>
            </Table>
            {/*{displayFilters &&*/}
            {/*    <div className={"btn-group-row"}>*/}
            {/*        <ButtonGroup className="table-filters">*/}
            {/*            <Button size={"small"}*/}
            {/*                label={`${currentPage ? `${(currentPage-1)*rowsPerPage+1}-${(currentPage-1)*rowsPerPage+rowsPerPage} of ${candidateCount}` : ""}`}*/}
            {/*            >*/}
            {/*            </Button>*/}
            {/*        </ButtonGroup>*/}
            {/*        <ButtonGroup className="table-filters">*/}
            {/*            <Button*/}
            {/*                size={"small"}*/}
            {/*                label={"<<"}*/}
            {/*                onClick={() => {getNextPage(["prev"])}}*/}
            {/*            />*/}
            {/*            <Button*/}
            {/*                size={"small"}*/}
            {/*                label={">>"}*/}
            {/*                onClick={() => {getNextPage(["next"])}}*/}
            {/*            />*/}
            {/*        </ButtonGroup>*/}
            {/*    </div>*/}
            {/*}*/}
        </>
    )
}

