import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

/**
 * Primary UI button component for user interaction
 */
export const Button = ({ variant, state, size, label, icon, iconColor, type= "button", className, ...props }) => {

  const buttonClass = `button ${className || ''}`.trim(); // Merge with existing class names

  return (
    <button
      type={type}
      className={buttonClass}
      data-variant={variant}
      data-size={size}
      data-state={state}
      data-has-label={label !== undefined}
      {...props}
    >
      <span className="reflection">
        <span className="top-right"/>
        <span className="bottom-left"/>
      </span>
      {icon &&
          <i className={`${icon}`} style={{color: iconColor ? iconColor : "inherit"}}></i>
      }
      <span>{label}</span>
    </button>
  );
};

Button.propTypes = {
  /**
   * Types of buttons:
   */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button label
   */
  label: PropTypes.string,
  /**
   * Button icon
   */
  icon: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   *  Optional additional class
   */
  className: PropTypes.string,
};
