import React, {useEffect, useContext} from 'react';
import "./MainMenu.scss";
import {useLocation, useNavigate} from "react-router-dom";
import { UserDispatchContext } from '../../context/UserContext';

/**
 * Page menu component for main UI
 */
export const MainMenu = ({isMenuOpen, setIsMenuOpen, isDisplayed, links = {}}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getUser } = useContext(UserDispatchContext);

    useEffect(() => {
        if (!window.matchMedia("(min-width: 900px)").matches) {
            setIsMenuOpen(false)
            localStorage.setItem("menuState", "collapsed");
        } else {
            const currMenuState = localStorage.getItem("menuState")
            if (currMenuState === 'collapsed') setIsMenuOpen(false)
            else setIsMenuOpen(true)
        }
    }, [setIsMenuOpen]);

    const handleOnMenuCollapse = () => {
        setIsMenuOpen(!isMenuOpen)
        if (isMenuOpen) localStorage.setItem("menuState", "collapsed")
        else localStorage.setItem("menuState", "expanded")
    }

    const checkUserGroups = (requiredGroups) => {
        const user = getUser()
        if(!user) return false;
        const userGroups = user.groups.map(group => group.name);
        return requiredGroups.every(group => userGroups.includes(group));
    }

    return (
        <div className={"main-side-menu"} data-variant={isMenuOpen ? "not-collapsed" : "collapsed"} data-display={isDisplayed}>
            {window.matchMedia("(min-width: 900px)").matches &&
                <button className={"menu-collapse-btn"} onClick={handleOnMenuCollapse}>
                    {isMenuOpen ?
                        <i className={"fa-solid fa-chevron-left"}></i>
                        :
                        <i className={"fa-solid fa-chevron-right"}></i>
                    }
                </button>
            }
            <ul className={"menu-navigation"}>
                {Object.entries(links).map((link, index) =>{
                    if(checkUserGroups(link.groups)) {
                    <li
                        key={"left-side-menu-link" + index}
                        className={"menu-link"}
                        data-active={location.pathname === link[1].url}
                        onClick={() => navigate(`.${link[1].url}`, { replace: true })}
                        data-tooltip={link[1].name}
                    >
                        <i className={link[1].icon}></i>
                        {isMenuOpen && link[1].name}
                    </li>
                    }
                })}
            </ul>
        </div>
    );
}