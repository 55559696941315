import React from 'react';
import "./Card.scss";

/**
 * Card component for main UI
 */
export default function Card ({children, variant, loading})  {
    Card.loading = loading || false
    return (
        <div className={"card"} data-variant={variant}>
            <div className={'bg-gradient'}/>
            {children}
        </div>
    );
}
Card.Header = function CardHeader ({children}) {
    return (
        <div className={"card-header"}>
            {children}
        </div>
    );
}

Card.Title = function CardTitle ({children}) {
    return (
        <h3 className={"card-title"}>
            {children}
        </h3>
    );
}

Card.Image = function CardIcon ({imgSource, imgAlt}) {
    return (
        <img className={"card-image"} src={imgSource} alt={imgAlt} />
    )
}

Card.Icon = function CardIcon ({icon}) {
    return (
       <i className={icon}></i>
    )
}

Card.Body = function CardBody ({children}) {
    return (
        <div className={"card-body"} data-loading={Card.loading}>
            {children}
        </div>
    );
}

Card.Description = function CardDescription ({children, reference}) {
    return (
        <div ref={reference} className={"card-description"}>
            {children}
        </div>
    );
}
