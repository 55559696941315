import React from 'react';
import "./Avatar.scss";

/**
 * Avatar component for main UI
 */
export const Avatar = ({initial, imgSrc, loading=false, ...props}) => {
    return (
        <div className={"avatar"} {...props} data-loading={loading}>
            <span>
                { imgSrc ? <img className={"avatar-img"} src={imgSrc} alt={"avatar image"} /> : <>{initial}</> }
            </span>
        </div>
    )
}

export const AvatarGroup = ({children}) => {
    return (
        <div className={"avatar-group"}>
            {children}
        </div>
    )
}

export const ClosableAvatar = ({initial, imgSrc, onClose, loading=false, ...props}) => {

    const close = (e) =>{
        e.stopPropagation()
        if(onClose)
            onClose()
    }

    return (
        <div className={"closable-avatar"}> 
            <span className={"close"} onClick={close}><i className="fa-duotone fa-circle-xmark"></i></span>
            <Avatar initial={initial} imgSrc={imgSrc} loading={loading} {...props}/>
        </div>
    )
}