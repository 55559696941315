import React, {useState} from "react";
import './Sandbox.scss'
import {Page} from "../../layouts/Page";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {Button} from "../../components/button/Button";
import {Avatar} from "../../components/avatar/Avatar";

/**
 * Sandbox Page
 */
export const Sandbox = () => {

    const handleOnClick = () => {
        console.log("Item click")
    }

    return (
        <Page style={{marginTop: '64px', gap: '48px', flexDirection: 'row', justifyContent: 'space-between'}}>
           <Dropdown>
                <Dropdown.Button>
                    <Button variant={"tertiary"} label={"Default"} size={"medium"} />
                </Dropdown.Button>
               <Dropdown.Menu>
                   <Dropdown.MenuItem onClick={handleOnClick}>Item</Dropdown.MenuItem>

               </Dropdown.Menu>
           </Dropdown>

            <Dropdown alignment={"center"}>
                <Dropdown.Button>
                    <Avatar initial={"M"} />
                </Dropdown.Button>
                <Dropdown.Menu>
                    <Dropdown.MenuItem onClick={handleOnClick}>Item</Dropdown.MenuItem>
                    <Dropdown.MenuItem onClick={handleOnClick}>Item</Dropdown.MenuItem>
                    <Dropdown.MenuItem onClick={handleOnClick}>Item</Dropdown.MenuItem>
                    <Dropdown.MenuItem onClick={handleOnClick}>Item</Dropdown.MenuItem>
                    <Dropdown.MenuItem onClick={handleOnClick}>Item</Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>

            <Dropdown alignment={"right"}>
                <Dropdown.Button>
                    <Button variant={"primary"} label={"Default"} size={"small"} />
                </Dropdown.Button>
                <Dropdown.Menu>
                    <Dropdown.MenuItem>Item</Dropdown.MenuItem>
                    <Dropdown.MenuItem>Item</Dropdown.MenuItem>
                    <Dropdown.MenuItem>Item</Dropdown.MenuItem>
                    <Dropdown.MenuItem>Item</Dropdown.MenuItem>
                    <Dropdown.MenuItem>Item</Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>

                <div></div>
        </Page>
    );
}