export const interviewStream = ({response, setResponse, setIsResponseDone}) => {
    const onSourceOpen = () => {
        setIsResponseDone(false)
        setResponse([])
    }

    const onSourceMessage = (event) => {
        const chunk = event.data.replaceAll('\\n', '\n').slice(1, -1)
        let currentResponseIndex = response.length

        setResponse(prevResponses => {
            const currentContent = prevResponses[currentResponseIndex] || ""
            const updatedContent = currentContent + chunk
            const updatedResponses = [...prevResponses]
            updatedResponses[currentResponseIndex] = updatedContent
            return updatedResponses
        })
    }

    const onSourceError = (event)=> {
        throw event
    }

    const onSourceClose = () => {
        setIsResponseDone(true)
    }

    return {onSourceOpen, onSourceMessage, onSourceError, onSourceClose}

}