import React from "react";
import content from '../../Meeting.json'
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import {Spinner} from "../../../../components/spinner/Spinner";
import {Button} from "../../../../components/button/Button";

export const GeneratedQuestions = ({selectedJob, questions, handleOnClick, isQuestionLoading}) => {
    return (
        <>
            {selectedJob && questions &&
                <div className={"interview-section-qa"}>
                    {questions[0] ?
                        <Markdown remarkPlugins={[remarkGfm]}>{questions[0]}</Markdown>
                        :
                        <>
                            {isQuestionLoading ?
                                <Spinner visible={true} variant={'line'} />
                                :
                                <Button
                                    label={content.buttonLabels.generateQuestions}
                                    variant={"tertiary"}
                                    size={"medium"}
                                    onClick={handleOnClick}
                                    style={{margin: "24px 0"}}
                                />
                            }
                        </>
                    }
                </div>
            }
            {!selectedJob &&
                <div className={"interview-section-inner-wrapper"}>
                    <span>{content.generatedQuestions.warningText}</span>
                </div>
            }
        </>
    )
}