import React, {useContext, useEffect, useState} from "react";
import content from '../../Meeting.json'
import {Container} from "../../../../components/container/Container";
import {InputField} from "../../../../components/input-field/InputField";
import {fetchDirect} from "../../../../apis/getRequest";
import {UserContext} from "../../../../context/UserContext";
import {getNameSuggestions} from "../../helpers/getSuggestions";
import {Spinner} from "../../../../components/spinner/Spinner";

export const CandidatesSearch = ({meetingSubject, findCV, isCVFound}) => {
    const { getToken } = useContext(UserContext)
    const [inputValue, setInputValue] = useState('')
    const [candidates, setCandidates] = useState([])
    const [suggestions, setSuggestions] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [selectedCandidates, setSelectedCandidates] = useState({})

    useEffect(() => {
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/candidates/?search_query=`, getToken())
            .then((response) => {
                setCandidates(response)
                setLoadingStatus(false)
            })
            .catch((e) => {console.error(e)})
    }, [getToken])

    //update candidate selection
    useEffect(() => {
        if (!selectedCandidates) return
        //TODO: post request to update candidate selection to find cv

    }, [selectedCandidates, setSelectedCandidates])

    //update suggestions
    useEffect(() => {
        if (!candidates || candidates.length <= 0) return
        setSuggestions(getNameSuggestions(candidates, inputValue))
    }, [candidates, inputValue])

    const handleOnInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const onClearInput = () => {
        setInputValue('')
    }

    const handleOnSuggestionClick = (suggestion) => {
        if (!suggestion?.text) return
        setInputValue(suggestion.text)
        setSelectedCandidates(suggestion.text)
        findCV(suggestion.id)
    }

    return (
        <div style={{position: 'relative', width: 'calc(100% - 12px)'}}>
            <div className={"panel-header"} title={meetingSubject}>
                <span className={"panel-header-title"}>{meetingSubject}</span>
            </div>
            <Container
                icon={content.findCandidate.icon}
                title={content.findCandidate.sectionName}
            >
                <Spinner visible={loadingStatus} />
                {!loadingStatus &&
                    <div className={"find-candidate-wrapper"}>
                        <InputField
                            id={"candidate-input"}
                            label={content.findCandidate.inputLabel}
                            value={inputValue}
                            variant={'secondary'}
                            variantType={'select'}
                            placeholder={content.findCandidate.inputPlaceholder}
                            suggestions={suggestions}
                            onChange={handleOnInputChange}
                            autoComplete={"off"}
                            handleOnSuggestionClick={handleOnSuggestionClick}
                            clearField={onClearInput}
                        />
                    </div>
                }
                {isCVFound === 'not-found' &&
                    <span className={"search-not-found"}>Pas de CV trouvé pour ce candidat.</span>
                }
            </Container>
        </div>
    )
}