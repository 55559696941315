import React from "react";
import './PageIntro.scss'

export function PageIntro({title, subTitle, description, variant}) {
    return (
        <div className={"page-intro"} data-variant={variant}>
            <div className={"page-header-bar"}></div>
            <h1 className={"page-header"}>
                <span className={"text-title"}>{title}</span><br/>
                <span className={"text-gradient"}>{subTitle}</span>
            </h1>
            {description &&
                <p className={"page-text"}>{description}</p>
            }
        </div>
    );
}