import content from '../../Meeting.json'
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import React from "react";
import {Dropdown} from "../../../../components/dropdown/Dropdown";

export const DocumentViewer = ({docs, meetingSubject, handelOnChangeCandidate}) => {
    return (
        <div style={{position: 'relative', width: 'calc(100% - 12px)'}}>
            <div className={"panel-header"} title={meetingSubject}>
                <span className={"panel-header-title"}>{meetingSubject}</span>
                <Dropdown alignment={"right"}>
                    <Dropdown.Button>
                        <button className={"panel-header-button"}>
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                        </button>
                    </Dropdown.Button>
                    <Dropdown.Menu>
                        <Dropdown.MenuItem onClick={handelOnChangeCandidate}>
                            <i className={content.dropdownCandidate[0].icon}></i>
                            <span>{content.dropdownCandidate[0].label}</span>
                        </Dropdown.MenuItem>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <DocViewer
                documents={docs}
                pluginRenderers={DocViewerRenderers}
                className="custom-doc-viewer-style"
                config={{pdfZoom: {defaultZoom: 1.1}}}
            />
        </div>
    )
}
