import {Tag} from "../../../components/tag/Tag";
import React from "react";

export const NestedRow = ({job}) => {
    return (
        <>
            <div className={"tag-group-status"}>
                <Tag label={`${job?.submissionsCount['submitted']||0} soumis`}/>
                <Tag label={`${job?.submissionsCount['inprocess']||0} en cours`}/>
                <Tag label={`${job?.submissionsCount['refused']||0} refusé`}/>
            </div>
            <div className={"nested-row-inner-wrapper"}>
                {(job?.submissions?.data && job?.submissions?.data.length > 0) &&
                    <div>
                        <h4 className={"candidate-header"}>Candidats soumis: </h4>
                        {job?.submissions?.data.map((submission, index) =>
                            <div className={"nested-row-candidate"} key={"nested-row-candidate-" + index}>
                                    <span className={"candidate-header-info"}>
                                        {`${submission.candidate?.firstName} ${submission.candidate?.lastName}`}
                                    </span>
                                <Tag label={`${submission.status}`} variant={"primary"}/>
                            </div>
                        )}
                    </div>
                }
                <div className={"tag-group"}>
                    <Tag label={job?.employmentType}/>
                    <Tag label={`${job?.hoursPerWeek} hrs`}/>
                    {job?.onSite && <Tag label={`${job?.onSite}`}/>}
                    {(job?.payRate || job?.payRate !== 0) && <Tag label={`$ ${job?.payRate} max`} />}
                </div>
            </div>
        </>
    )
}