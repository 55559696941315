import React, {useContext, useState, useEffect} from 'react';
import {putRequest, deleteRequest } from "../../../apis/postRequest.js";
import {fetchDirect} from "../../../apis/getRequest";
import {UserContext} from "../../../context/UserContext";
import {Avatar, AvatarGroup, ClosableAvatar} from "../../../components/avatar/Avatar.jsx";
import {Table} from "../../../components/table/Table.jsx";
import {Tag} from "../../../components/tag/Tag";
import { ButtonGroup } from '../../../components/button-group/ButtonGroup.jsx';
import { Button } from '../../../components/button/Button.jsx';
import {prepareJobs} from "../helpers/JobsPrepare";
import PropTypes from "prop-types";
import { ModalList } from '../../../components/modal/Modal.jsx';
import {NestedRow} from "./NestedRow";

export const JobTable = ({handleRowSelect, displayFilters, userFilter}) => {
    const { getToken } = useContext(UserContext);
    const [jobs, setJobs] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [avatarLoadingStatus, setAvatarLoadingStatus] = useState({});
    const [usersPics, setUsersPics] = useState({})
    const [jobTypes, setJobTypes] = useState([1])

    const [modalRecruiterVisibility, setModalRecruiterVisibility] = useState(false)
    const [recruiterList, setRecruiterList] = useState([])
    const [modalRecruiterEvent, setModalRecruiterEvent] = useState(null)

    const [modalJobtypeVisibility, setModalJobtypeVisibility] = useState(false)
    const [modalJobtypeEvent, setModalJobtypeEvent] = useState(null)
    const [modalCurrentJob, setModalCurrentJob] = useState(null)

    const [rowsPerPage] = useState(10)
    const [totalCount, setTotalCount] = useState(100)

    const loadJob = async () =>{
         const params = {
            "job_types": displayFilters ? jobTypes.join(",") : "1,2,3"
        }
        if(userFilter)
            params["owner_id"] = userFilter

        const response = await fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/`, getToken(), params);
        const data_1 = response.data;
        return prepareJobs(data_1);
    }

    async function refreshJob(loading=true){
        if(refreshJob){
            setJobs([])
            setLoadingStatus(loading)
        }
        const jobs = await loadJob();
        window.scrollTo(0, 0);
        setJobs(jobs);
        setLoadingStatus(false);
    }

    useEffect(() => {
        refreshJob()
    }, [jobTypes, setJobTypes]);


    useEffect(() =>{
        fetchDirect(`${process.env.REACT_APP_API_HOSTNAME}/users/pictures`, getToken())
        .then((pictures) => {
            const usersPics = {}
            const recruiters = []
            pictures?.forEach(p => {
                usersPics[p.integration_id] = p.profile_pic
                const avatar = <Avatar initial={p.name[0]} imgSrc={p.profile_pic ? `data:image/jpeg;base64,${p.profile_pic}` : null}/>
                //recruiters.push({id: p.integration_id, text:p.name, content:avatar})
                recruiters.push({id: p.integration_id, content:avatar})
            })
            setUsersPics(usersPics)
            setRecruiterList(recruiters.reverse())
        });
    },[getToken])

    const formatDate = (timestamp) =>{
        const dateFormat = {day: '2-digit', month: '2-digit', year: 'numeric'}
        return new Intl.DateTimeFormat('ca-FR', dateFormat).format(timestamp)
    }

    const daysSince = (timestamp) => {
        const now = new Date();
        const then = new Date(timestamp);
        const differenceInTime = now.getTime() - then.getTime();
        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays + 1;
    }

    const iconTypes = {
        "1" : "fa-solid fa-circle",
        "2" : "fa-solid fa-circle",
        "3" : "fa-solid fa-circle"
    }

    const getTypeIcon = (type) => {
        if (type === 1)
            return <i className={`${iconTypes[1]}`} style={{color: "#b72b50"}}></i>
        else if (type === 2)
            return <i className={`${iconTypes[2]}`} style={{color: "#d7a66e"}}></i>

        return <i className={`${iconTypes[3]}`} style={{color: "#8e8e96"}}></i>
    }

    const getRecruiterUpdateUrl = (jobId, recruiterId) => `${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/${jobId}/recruiters/?ids=${recruiterId}`

    const setAvatarLoading = (jobId, recruiterId, status) => setAvatarLoadingStatus(prev => ({...prev, [`${jobId}-${recruiterId}`]: status}))

    const removeRecruiter = (jobId, recruiterId) =>{
        setAvatarLoading(jobId, recruiterId, true)
        const url = getRecruiterUpdateUrl(jobId,recruiterId)
        deleteRequest({url:url, token:getToken()})
            .then(() => refreshJob())
            .then(()=> setAvatarLoading(jobId, recruiterId, false))
    }

    const pickRecruiter = (e, jobId) =>{
        e.stopPropagation();
        setModalRecruiterEvent(e)
        setModalCurrentJob(jobId)
        setModalRecruiterVisibility(prevVisibility => !prevVisibility)
    }

    const assignRecruiter = (recruiterId) =>{
        setModalRecruiterVisibility(false)
        setAvatarLoading(modalCurrentJob, 'add', true)
        const url = getRecruiterUpdateUrl(modalCurrentJob, recruiterId)
        putRequest({url:url, token:getToken()})
        .then(() => refreshJob())
        .then(() => setAvatarLoading(modalCurrentJob, 'add', false))
    }

    const pickJobtype = (e, jobId) =>{
        e.stopPropagation();
        setModalJobtypeEvent(e)
        setModalCurrentJob(jobId)
        setModalJobtypeVisibility(prevVisibility => !prevVisibility)
    }

    const assignJobtype = (jobType) =>{
        setModalJobtypeVisibility(prevVisibility => !prevVisibility)
        const jobId = modalCurrentJob
        const url = `${process.env.REACT_APP_API_HOSTNAME}/recruitment/jobs/${jobId}?job_type=${jobType}`
        putRequest({url:url, token:getToken()})
        .then(() => refreshJob())
    }

    function assignJobTypes(types){
        setJobTypes(types)
    }

     return (
        <>
            {displayFilters &&
                <div id="table-filters" className={"btn-group-row"}>
                    <ButtonGroup>
                        <Button size={"small"} label={"Tout"} data-active={jobTypes.length === 3} onClick={() =>{assignJobTypes([1, 2, 3])}}></Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button size={"small"} icon={iconTypes[1]} iconColor={"#b72b50"} data-active={jobTypes.length === 1 && jobTypes[0] === 1} onClick={() =>{assignJobTypes([1])}}></Button>
                        <Button size={"small"} icon={iconTypes[2]} iconColor={"#d7a66e"} data-active={jobTypes.length === 1 && jobTypes[0] === 2} onClick={() =>{assignJobTypes([2])}}></Button>
                        <Button size={"small"} icon={iconTypes[3]} iconColor={"#8e8e96"} data-active={jobTypes.length === 1 && jobTypes[0] === 3} onClick={() =>{assignJobTypes([3])}}></Button>
                    </ButtonGroup>
                </div>
            }

            <Table state={loadingStatus ? "loading" : "default"}>
                <Table.Head numOfColumns={7}>
                    <Table.Header colWidth={'56px'} leftSticky={true}></Table.Header>
                    <Table.Header colWidth={'56px'}></Table.Header>
                    <Table.Header>Id</Table.Header>
                    <Table.Header>Client</Table.Header>
                    <Table.Header>Titre du poste</Table.Header>
                    <Table.Header align={"center"}>Depuis</Table.Header>
                    {/*<Table.Header align={"center"} condensedHorizontally={false}>Soumis</Table.Header>*/}
                    {/*<Table.Header align={"center"} condensedHorizontally={false}>En cours</Table.Header>*/}
                    {/*<Table.Header align={"center"} condensedHorizontally={false}>Refusé</Table.Header>*/}
                    <Table.Header>Recruteurs</Table.Header>
                </Table.Head>
                <Table.Body>
                    {jobs?.map((job, index) => (
                        <Table.Row
                            index={index}
                            key={"table-row-" + index}
                            onClick={(e) => handleRowSelect(job?.id, e)}
                            id={job?.id}
                            nestedRow={<NestedRow job={job}/>}
                        >
                            <Table.Cell clickable={false} leftSticky={true} padding={false}><Table.Chevron/></Table.Cell>
                            <Table.Cell align={"center"} onClick={(e) => pickJobtype(e, job?.id)}>{getTypeIcon(job?.type)}</Table.Cell>
                            <Table.Cell>{job?.id}</Table.Cell>
                            <Table.Cell>{job?.clientCorporation?.name}</Table.Cell>
                            <Table.Cell>{job?.title}{job.customText1 ? ` (${job.customText1})` : ''}</Table.Cell>
                            <Table.Cell align={"center"}>
                                <Tag label={daysSince(job?.dateAdded) + " j"}  />
                            </Table.Cell>
                            {/*<Table.Cell align={"center"} condensedHorizontally={false}>*/}
                            {/*    <Tag label={job?.submissionsCount['submitted'] || 0} variant="tertiary"/>*/}
                            {/*</Table.Cell>*/}
                            {/*<Table.Cell align={"center"} condensedHorizontally={false}>*/}
                            {/*    <Tag label={job?.submissionsCount['inprocess'] || 0} />*/}
                            {/*</Table.Cell>*/}
                            {/*<Table.Cell align={"center"} condensedHorizontally={false}>*/}
                            {/*    <Tag label={job?.submissionsCount['refused'] || 0} variant="primary"/>*/}
                            {/*</Table.Cell>*/}
                            <Table.Cell>
                                <AvatarGroup>
                                    {job?.assignedUsers?.data.map(user => (
                                        <ClosableAvatar
                                            key={"avatar-" + user?.id}
                                            initial={user?.firstName[0] + user?.lastName[0]}
                                            imgSrc={usersPics[user?.id] ? `data:image/jpeg;base64,${usersPics[user?.id]}` : null}
                                            onClose={() => removeRecruiter(job.id, user.id, job.assignedUsers.data)}
                                            loading={avatarLoadingStatus[`${job?.id}-${user.id}`] || false}
                                        />
                                    ))}
                                    <div className="action-line-buttons">
                                        <Avatar
                                            key={`avatar-add-${job?.id}`}
                                            initial={"+"}
                                            onClick={(e) => pickRecruiter(e, job?.id)}
                                            loading={avatarLoadingStatus[`${job?.id}-add`] || false}
                                        />
                                    </div>
                                </AvatarGroup>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer>
                    {/*<Table.Pagination*/}
                    {/*    totalPageNum={Math.ceil(totalCount / rowsPerPage)}*/}
                    {/*    // prevClick={}*/}
                    {/*    // nextClick={}*/}
                    {/*/>*/}
                </Table.Footer>
            </Table>
            <ModalList
                items={recruiterList}
                event={modalRecruiterEvent}
                onClick={assignRecruiter}
                show={modalRecruiterVisibility}
                modalHeight={100}
                modalWidth={50}
            />
            <ModalList
                items={[{ id:1, text:getTypeIcon(1)}, { id:2, text:getTypeIcon(2) }, { id:3, text:getTypeIcon(3)}]}
                event={modalJobtypeEvent}
                onClick={assignJobtype}
                show={modalJobtypeVisibility}
                modalHeight={100}
                modalWidth={30}
            />
        </>
    )
}

function SuggestionList(props) {
    return <ul id={"auto-complete-" + props.id} className={"auto-complete-list"} data-pos={props.dataPos}>
        {props.map}
    </ul>;
}

SuggestionList.propTypes = {
    id: PropTypes.any,
    dataPos: PropTypes.string,
    map: PropTypes.any
};