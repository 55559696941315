export const formatAddress = (address) => {
    if(!address) return undefined
    const formatAddressLine = (addressLine) => {
        if (!addressLine) return ""
        return addressLine?.trim()
    }
    const address1 = formatAddressLine(address?.address1)
    const address2 = formatAddressLine(address?.address2)
    const city = formatAddressLine(address?.city)
    const state = formatAddressLine(address?.state)
    const country = formatAddressLine(address?.countryName)
    const zip = formatAddressLine(address?.zip)
    return (
        `${address1} ${address2} ${city.length > 0 ? city.concat(', ') : ''}${state} ${country} ${zip}`
    )
}